<script lang="ts" setup>
import { ADialogToolbar, AFormTextarea, AFormTextField, ARow } from '@anzusystems/common-admin'
import { useI18n } from 'vue-i18n'
import type { Newsletter } from '@/types/cms/Newsletter'
import { useGeneratorSettingsDialogActions } from '@/views/cms/newsletter/composables/newsletterGeneratorSettingsActions'

withDefaults(
  defineProps<{
    newsletter: Newsletter
  }>(),
  {}
)

const { t } = useI18n()

const { dialog, currentGeneratorSettings, createOrUpdateGeneratorSettings, saveButtonLoading, closeDialog } =
  useGeneratorSettingsDialogActions()

const onConfirm = () => {
  createOrUpdateGeneratorSettings()
}
</script>

<template>
  <VDialog
    :model-value="dialog"
    :width="500"
  >
    <VCard v-if="dialog">
      <ADialogToolbar @on-cancel="closeDialog">
        {{ t('cms.newsletter.model.generatorSettings.title') }}
      </ADialogToolbar>
      <VCardText>
        <ARow>
          <AFormTextField
            v-model="currentGeneratorSettings.templateId"
            :label="t('cms.newsletter.model.generatorSettings.templateId')"
          />
        </ARow>
        <ARow>
          <AFormTextarea
            v-model="currentGeneratorSettings.dataSourceUrl"
            :label="t('cms.newsletter.model.generatorSettings.dataSourceUrl')"
          />
        </ARow>
        <ARow>
          <VSwitch
            v-model="currentGeneratorSettings.useEmailSubjectFromSource"
            :label="t('cms.newsletter.model.generatorSettings.useEmailSubjectFromSource')"
          />
        </ARow>
        <ARow>
          <AFormTextarea
            v-model="currentGeneratorSettings.dataSourceUrlDynamicParam"
            :label="t('cms.newsletter.model.generatorSettings.dataSourceUrlDynamicParam')"
          />
        </ARow>
        <ARow>
          <AFormTextField
            v-model="currentGeneratorSettings.articleMinLimit"
            :label="t('cms.newsletter.model.generatorSettings.articleMinLimit')"
          />
        </ARow>
      </VCardText>
      <VCardActions>
        <VSpacer />
        <ABtnTertiary @click.stop="closeDialog">
          {{ t('common.button.cancel') }}
        </ABtnTertiary>
        <ABtnPrimary
          :loading="saveButtonLoading"
          @click.stop="onConfirm"
        >
          {{ t('common.button.confirm') }}
        </ABtnPrimary>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
