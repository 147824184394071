import { SYSTEM_CMS } from '@/model/systems'
import type { ArticlePublicPreview } from '@/types/cms/ArticlePublicPreview.ts'
import { dateTimeNow, type IntegerId } from '@anzusystems/common-admin'

export function useArticlePublicPreviewFactory() {
  const create = (articleId: IntegerId): ArticlePublicPreview => {
    return {
      id: 0,
      article: articleId,
      url: '',
      expiresAt: null,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      _resourceName: 'articlePublicPreview',
      _system: SYSTEM_CMS,
    }
  }

  return {
    create,
  }
}
