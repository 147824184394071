import { DashboardType, type DashboardTypeType } from '@/model/cms/valueObject/DashboardType'
import type { IntegerId } from '@anzusystems/common-admin'

export interface DashboardDtoInterface {
  type: DashboardTypeType
  id: IntegerId
}

export interface DeskDashboardDtoInterface extends DashboardDtoInterface {
  type: typeof DashboardType.Desk
  name: string
  follower: boolean
}

export interface StageDashboardDtoInterface extends DashboardDtoInterface {
  type: typeof DashboardType.Stage
  name: string
  follower: boolean
}

export interface PersonalDashboardDtoInterface extends DashboardDtoInterface {
  type: typeof DashboardType.Personal
}

export const isDeskDashboardDto = (item: DashboardDtoInterface | null): item is DeskDashboardDtoInterface => {
  return !!item && item.type === DashboardType.Desk
}

export const isPersonalDashboardDto = (item: DashboardDtoInterface | null): item is PersonalDashboardDtoInterface => {
  return !!item && item.type === DashboardType.Personal
}

export const isStageDashboardDto = (item: DashboardDtoInterface | null): item is StageDashboardDtoInterface => {
  return !!item && item.type === DashboardType.Stage
}
