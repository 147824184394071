import {
  type DocId,
  type FilterBag,
  isNumber,
  isUndefined,
  type Pagination,
  useAlerts,
} from '@anzusystems/common-admin'
import { completeArticleStage, createArticleStage, fetchArticleStageList } from '@/services/api/cms/articleStageApi'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import type { ArticleStage, ArticleStageCompleteDto, ArticleStageCreateDto } from '@/types/cms/ArticleStage'
import { useArticleStageListStore } from '@/stores/cms/articleStageStore'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'
import { useCachedDesks } from '@/views/cms/desk/composables/cachedDesks'
import { useCachedStages } from '@/views/cms/stage/composables/cachedStages'
import { useStages } from '@/views/cms/stage/composables/stagesAll'
import { fetchArticleKindListByDocIds } from '@/services/api/cms/articleKindApi'
import type { ArticleKind } from '@/types/cms/ArticleKind/ArticleKind'
import { isArticleKindStandard } from '@/types/cms/ArticleKind/ArticleKindStandard'

const { showRecordWas, showErrorsDefault } = useAlerts()

const datatableHiddenColumns = ref<Array<string>>([])
const listLoading = ref(false)
const stageActionsLoading = ref(false)

export const useArticleStageCreateActions = () => {
  const stageCreating = ref(false)
  const create = async (dto: ArticleStageCreateDto, callbackSuccess: (() => void) | undefined = undefined) => {
    try {
      stageActionsLoading.value = true
      stageCreating.value = true
      await createArticleStage(dto)
      if (!isUndefined(callbackSuccess)) callbackSuccess()
      showRecordWas('created')
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      stageActionsLoading.value = false
      stageCreating.value = false
    }
  }

  return {
    stageActionsLoading,
    stageCreating,
    create,
  }
}

export const useArticleStageCompleteActions = () => {
  const stageCompleting = ref(false)
  const complete = async (dto: ArticleStageCompleteDto, callbackSuccess: (() => void) | undefined = undefined) => {
    try {
      stageActionsLoading.value = true
      stageCompleting.value = true
      await completeArticleStage(dto)
      if (!isUndefined(callbackSuccess)) callbackSuccess()
      showRecordWas('created')
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      stageActionsLoading.value = false
      stageCompleting.value = false
    }
  }

  return {
    stageCompleting,
    complete,
  }
}

export const useArticleStageListActions = () => {
  // todo what to fetch and cache //  // todo fetch list modes by columns?
  const { addToCachedUsers, fetchCachedUsers } = useCachedUsers()
  const { addToCachedDesks, fetchCachedDesks } = useCachedDesks()
  const { addToCachedStages, fetchCachedStages } = useCachedStages()
  const { loadStages } = useStages()

  const articleStageOneStore = useArticleStageListStore()
  const { listItems, articleRelLoading } = storeToRefs(articleStageOneStore)

  const fetchList = async (pagination: Pagination, filterBag: FilterBag) => {
    listLoading.value = true
    try {
      const res = await fetchArticleStageList(pagination, filterBag)

      const articleDocIds: DocId[] = []
      res.forEach((articleStage: ArticleStage) => {
        if (isNumber(articleStage.completedBy)) {
          addToCachedUsers(articleStage.completedBy)
        }
        addToCachedUsers(articleStage.createdBy)
        addToCachedDesks(articleStage.desk)
        addToCachedStages(articleStage.stage)

        articleDocIds.push(articleStage.articleDocId)
      })

      fetchCachedUsers()
      fetchCachedDesks()
      fetchCachedStages()
      loadStages()

      articleRelLoading.value = true
      fetchArticleKindListByDocIds(articleDocIds).then((value: ArticleKind[]) => {
        res.forEach((articleStage: ArticleStage) => {
          const article = value.find((value: ArticleKind) => value.docId === articleStage.articleDocId)

          if (!isUndefined(article) && isArticleKindStandard(article)) {
            articleStageOneStore.addArticleKind(articleStage.id, article)
          }
        })

        articleRelLoading.value = false
      })

      listItems.value = res
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }
  }

  return {
    datatableHiddenColumns,
    listLoading,
    fetchList,
    listItems,
    articleRelLoading,
  }
}
