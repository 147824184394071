<script setup lang="ts">
import { useCollabState } from '@anzusystems/common-admin'
import { useI18n } from 'vue-i18n'

const { collabReconnecting } = useCollabState()

const onClick = () => {
  window.location.reload()
}

const { t } = useI18n()
</script>

<template>
  <VAppBar
    v-if="collabReconnecting"
    height="32"
    color="orange accent-3"
    elevation="0"
    :order="-1"
  >
    <div class="text-center w-100 text-caption pb-1">
      <span v-once>
        {{ t('system.topbar.collabAlert.disconnected') }}
        <span
          class="cursor-pointer text-decoration-underline"
          @click.stop="onClick"
        >
          {{ t('system.topbar.collabAlert.disconnectedReload') }}
        </span>
      </span>
    </div>
  </VAppBar>
</template>
