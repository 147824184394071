import { reactive } from 'vue'
import { makeFilterHelper } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/inboxApi'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

const filter = reactive({
  id: {
    ...makeFilter({ name: 'id' }),
  },
  title: {
    ...makeFilter({ name: 'title', variant: 'startsWith', field: 'texts.title' }),
  },
})

export function useInboxListFilter() {
  return filter
}

export function useInboxFilter() {
  return reactive({
    id: {
      ...makeFilter({ name: 'id', variant: 'in', default: [] }),
    },
    title: {
      ...makeFilter({ name: 'title', variant: 'startsWith', field: 'texts.title' }),
    },
  })
}
