import { type ScrapeStatusType } from '@/components/anzutap/types/EmbedExternal'
import {
  type DatetimeUTC,
  type DatetimeUTCNullable,
  type IntegerId,
  type JobStatusType,
} from '@anzusystems/common-admin'
import type { EventBusKey } from '@vueuse/core'
import { useEventBus } from '@vueuse/core'

export const appNotificationsEventBusKey: EventBusKey<AppNotification> = Symbol('anzu:appNotificationsEventBusKey')

export function useAppNotificationsEventBus() {
  return useEventBus<AppNotification>(appNotificationsEventBusKey)
}

export const AppNotificationMessageType = {
  EmbedKindExternal: 'embedKindExternal',
  Job: 'job',
  Task: 'task',
  Notification: 'notification',
} as const

interface AppNotificationMessageAware {
  eventId: string
  lang: string
  type: string
  title: string
  subjectId: string
  bodies: MessageBody[]
  uris: MessageUri[]
  images: MessageImage[]
  attributes: Record<string, unknown>
}

export interface AppNotificationMessageEmbedKindExternal extends AppNotificationMessageAware {
  type: typeof AppNotificationMessageType.EmbedKindExternal
  id: string
  subtitle: string
  createdAt: DatetimeUTC
  updatedAt: DatetimeUTC
  publishedAt: DatetimeUTC
  deleteAt: DatetimeUTC
  attributes: {
    status: ScrapeStatusType
  }
}

export interface AppNotificationMessageJob extends AppNotificationMessageAware {
  type: typeof AppNotificationMessageType.Job
  attributes: {
    status: JobStatusType
  }
}

export interface AppNotificationMessageTask extends AppNotificationMessageAware {
  type: typeof AppNotificationMessageType.Task
  attributes: {
    descriptionShort: string
  }
}

export interface AppNotificationMessageNotification extends AppNotificationMessageAware {
  type: typeof AppNotificationMessageType.Notification
}

interface MessageUri {
  id: string
  type: string
}

interface MessageBody {
  content: string
  type: string
}

interface MessageImage {
  id: string
  type: string
  width: number
  height: number
  alt: string
}

export interface AppNotification {
  id: string
  createdAt: DatetimeUTC
  lastReadAt: DatetimeUTCNullable
  message: AppNotificationMessage
  destination: {
    type: 'browser'
  }
  read: boolean
  userId: IntegerId
}

export type AppNotificationMessage =
  | AppNotificationMessageEmbedKindExternal
  | AppNotificationMessageJob
  | AppNotificationMessageNotification
  | AppNotificationMessageTask
