<script setup lang="ts">
import { useOwl } from '@/composables/owl/owl'
import { envConfig } from '@/services/EnvConfigService'
import { useHighcharts } from '@/services/highcharts/Highcharts'
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import { useArticleSumMetricsOneStore } from '@/stores/cms/articleSumMetricsStore'
import { isArticleKindStandard } from '@/types/cms/ArticleKind/ArticleKindStandard'
import type { CollabRoom } from '@/types/Collab'
import type { PlotLine } from '@/types/highcharts/Highcharts'
import {
  ChartIntervalDefault,
  ChartIntervalDefaultForMiddleAgeArticle,
  ChartIntervalDefaultForOldArticle,
  useChartInterval,
} from '@/types/owl/Chart'
import { MetricGroupBy, type MetricGroupByType } from '@/types/owl/Metric'
import { AChipNoLink, ARow, type CollabStatusType, isArray, isNumber } from '@anzusystems/common-admin'
import { useElementVisibility } from '@vueuse/core'
import Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'
import { storeToRefs } from 'pinia'
import { nextTick, ref, useTemplateRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)
const articleKindOneStore = useArticleKindOneStore()
const { article } = storeToRefs(articleKindOneStore)
const { t } = useI18n()
const {
  initializeHighcharts,
  formatTimeSpent,
  addPlotLine,
  removePlotLines,
  getNearestScaleDate,
  defaultLineChartOptions,
} = useHighcharts()
const plotLines: PlotLine[] = []
const { loadArticleTimeMetrics } = useOwl()

const lineChartOptions: Highcharts.Options = {
  tooltip: {
    pointFormatter: function () {
      if (this.series.name === t('cms.articleKind.widget.basicStatistics.lineChart.series.spentTime')) {
        return `<br><span style="color:${this.color}">\u25CF</span> ${this.series.name}: ${formatTimeSpent(this.y)}`
      }

      return `<br><span style="color:${this.color}">\u25CF</span> ${this.series.name}: ${this.y?.toLocaleString()}`
    },
    shared: true,
  },
  xAxis: {
    type: 'datetime',
    gridLineWidth: 1,
    crosshair: true,
    title: {
      text: t('cms.articleKind.widget.basicStatistics.lineChart.date'),
    },
    plotLines: [],
  },
  yAxis: [
    {
      title: {
        text: t('cms.articleKind.widget.basicStatistics.lineChart.views'),
      },
      min: 0,
      opposite: false,
    },
    {
      title: {
        text: t('cms.articleKind.widget.basicStatistics.lineChart.spentTime'),
      },
      labels: {
        formatter: function () {
          if (isNumber(this.value)) {
            return formatTimeSpent(this.value)
          }
          return ''
        },
      },
      opposite: true,
    },
  ],
  series: [
    {
      name: t('cms.articleKind.widget.basicStatistics.lineChart.series.pageViews'),
      type: 'line',
      lineWidth: 1,
      data: [],
      yAxis: 0,
    },
    {
      name: t('cms.articleKind.widget.basicStatistics.lineChart.series.qualityRead'),
      type: 'line',
      lineWidth: 1,
      data: [],
      yAxis: 0,
    },
    {
      name: t('cms.articleKind.widget.basicStatistics.lineChart.series.spentTime'),
      type: 'line',
      lineWidth: 1,
      data: [],
      yAxis: 1,
    },
  ],
}

const chartOptions = { ...defaultLineChartOptions, ...lineChartOptions }

/**
 * Returns article age in seconds since the first publication or null if the article wasn't published.
 */
function getArticleAge(): number | null {
  if (!article.value.dates.firstPublishedAt) {
    return null
  }

  const firstPublishedAt = new Date(article.value.dates.firstPublishedAt)
  const now = new Date()
  return (now.getTime() - firstPublishedAt.getTime()) / 1000
}

const refreshChartData = async (intervalStr: string): Promise<void> => {
  if (!envConfig.owl.enabled || article.value.owlObjectId === null) {
    return
  }

  const now = new Date()
  const articleAge = getArticleAge()
  let groupBy: MetricGroupByType = MetricGroupBy.Day
  let since: Date | undefined = undefined

  if (articleAge !== null && articleAge < 3600 * 24 * 30) {
    // for article younger than 30 days we have still hourly data
    groupBy = MetricGroupBy.Hour
  }

  // use the publication date if available as default chart start time
  if (article.value.dates.firstPublishedAt) {
    since = new Date(article.value.dates.firstPublishedAt)
    // add hour or day for smoother graphs
    if (articleAge !== null && articleAge > 30 * 24 * 3600) {
      // article is older than 30 days, the data will be grouped by date
      since.setDate(since.getDate() + 1)
    } else {
      since.setHours(since.getHours() + 1)
    }
  }

  switch (intervalStr) {
    case '24h':
      groupBy = MetricGroupBy.Minute
      since = new Date(now.getTime() - 24 * 3600 * 1000)
      if (articleAge !== null && articleAge < 24 * 3600 && article.value.dates.firstPublishedAt) {
        // article is younger than 1 day
        since = new Date(article.value.dates.firstPublishedAt)
      }
      break
    case '7d':
      groupBy = MetricGroupBy.Hour
      since = new Date(now.getTime() - 7 * 24 * 3600 * 1000)
      if (articleAge !== null && articleAge < 7 * 24 * 3600 && article.value.dates.firstPublishedAt) {
        // article is younger than 7 days
        since = new Date(article.value.dates.firstPublishedAt)
      }
      break
  }

  const chart = chartComponentRef.value?.chart
  const articleMetrics = await loadArticleTimeMetrics(article.value.owlObjectId, groupBy, since)
  if (articleMetrics === undefined) {
    return
  }

  if (chartOptions.xAxis && !isArray(chartOptions.xAxis) && chartOptions.xAxis.plotLines !== undefined) {
    chartOptions.xAxis.plotLines = []
  }

  // remove previous plot lines
  if (chart) {
    removePlotLines(chart, plotLines)
  }

  plotLines.splice(0, plotLines.length)

  for (const objectEvent of articleMetrics.objectEvents) {
    const plotLine: PlotLine = {
      id: objectEvent.id,
      title: objectEvent.title,
      description: objectEvent.description,
      date: new Date(objectEvent.triggeredAt),
      chartDate: getNearestScaleDate(new Date(objectEvent.triggeredAt), groupBy, since),
      level: objectEvent.level,
      tooltipElement: null,
    }

    if (chart) {
      addPlotLine(chart, chartOptions, plotLine)
    }
    plotLines.push(plotLine)
  }

  if (chartOptions.series && chartOptions.series[0] && chartOptions.series[0].type === 'line') {
    chartOptions.series[0].data = articleMetrics.viewPoints
  }

  if (chartOptions.series && chartOptions.series[1] && chartOptions.series[1].type === 'line') {
    chartOptions.series[1].data = articleMetrics.qualityViewPoints
  }

  if (chartOptions.series && chartOptions.series[2] && chartOptions.series[2].type === 'line') {
    chartOptions.series[2].data = articleMetrics.timeSpentPoints
  }

  chartOptionsRef.value = { ...chartOptions }
}

const { chartIntervalOptions } = useChartInterval()

const onIntervalChange = async () => {
  await refreshChartData(selectedInterval.value)
}

const chartOptionsRef = ref(chartOptions)
const chartComponentRef = useTemplateRef<InstanceType<typeof Chart>>('chartComponentRef')
const selectedInterval = ref<string>(ChartIntervalDefault)

const articleSumMetricsOneStore = useArticleSumMetricsOneStore()
const { articleSumMetrics } = storeToRefs(articleSumMetricsOneStore)
const componentRef = useTemplateRef<HTMLDivElement>('componentRef')
const isVisible = useElementVisibility(componentRef)
const unwatch = watch(isVisible, async (visible) => {
  if (!visible) {
    return
  }
  unwatch()
  await nextTick()

  // initialize highcharts translations
  initializeHighcharts()

  // select chart interval based on the article age since first publication
  selectedInterval.value = ChartIntervalDefault
  const articleAge = getArticleAge()
  if (articleAge !== null) {
    if (articleAge > 3600 * 24 && articleAge < 3600 * 24 * 7) {
      selectedInterval.value = ChartIntervalDefaultForMiddleAgeArticle
    } else if (articleAge > 3600 * 24 * 7) {
      selectedInterval.value = ChartIntervalDefaultForOldArticle
    }
  }

  await refreshChartData(selectedInterval.value)
})
</script>

<template>
  <div ref="componentRef">
    <ARow v-if="isArticleKindStandard(article)">
      <span>{{ t('cms.articleKind.widget.basicStatistics.charCountOverall') }}: </span>
      <AChipNoLink color="blue">
        {{ article.attributesStandard.bodyTextCharCount }}
      </AChipNoLink>
    </ARow>
    <ARow v-if="isArticleKindStandard(article)">
      <span>{{ t('cms.articleKind.widget.basicStatistics.charCountBeforePaywall') }}: </span>
      <AChipNoLink color="blue">
        {{ article.attributesStandard.publicVisibleBodyCharCount }}
      </AChipNoLink>
    </ARow>

    <ARow v-if="isArticleKindStandard(article) && article.stats">
      <span>{{ t('cms.articleKind.widget.basicStatistics.pageViewAll') }}: </span>
      <AChipNoLink color="blue">
        {{ article.stats.viewCount }}
      </AChipNoLink>
      <span>{{ t('cms.articleKind.widget.basicStatistics.loggedUsers') }}: </span>
      <AChipNoLink color="blue">
        {{ article.stats.subscriberViewCount }}
      </AChipNoLink>
    </ARow>

    <ARow v-if="isArticleKindStandard(article) && article.stats">
      <span>{{ t('cms.articleKind.widget.basicStatistics.qualityReadAll') }}: </span>
      <AChipNoLink color="blue">
        {{ article.stats.qualityReadsCount }}
      </AChipNoLink>
      <span>{{ t('cms.articleKind.widget.basicStatistics.loggedUsers') }}: </span>
      <AChipNoLink color="blue">
        {{ article.stats.subscriberQualityReadsCount }}
      </AChipNoLink>
    </ARow>

    <ARow v-if="isArticleKindStandard(article)">
      <span>{{ t('cms.articleKind.widget.basicStatistics.timeSpentAll') }}: </span>
      <AChipNoLink color="blue">
        {{ formatTimeSpent(articleSumMetrics.all.timeSpentCount) }}
      </AChipNoLink>
    </ARow>

    <ARow>
      <VSelect
        v-model="selectedInterval"
        :items="chartIntervalOptions"
        :label="t('cms.articleKind.widget.basicStatistics.chartInterval.label')"
        @update:model-value="onIntervalChange"
      />
    </ARow>
    <ARow>
      <div class="chart-container">
        <Chart
          ref="chartComponentRef"
          :options="chartOptionsRef"
        />
      </div>
    </ARow>
  </div>

  <!--  <div class="d-flex gc-2 align-center mb-3">-->
  <!--    <span>{{ t('cms.articleKind.widget.basicStatistics.timeToSpendLoggedUser') }}: </span>-->
  <!--    <AChipNoLink color="blue">-->
  <!--      TODO: 89-->
  <!--    </AChipNoLink>-->
  <!--  </div>-->
  <!--  <div class="d-flex gc-2 align-center mb-3">-->
  <!--    <span>{{ t('cms.articleKind.widget.basicStatistics.timeToSpendSubbedUser') }}: </span>-->
  <!--    <AChipNoLink color="blue">-->
  <!--      TODO 152-->
  <!--    </AChipNoLink>-->
  <!--  </div>-->
</template>

<style lang="scss">
.chart-plot-line-tooltip-label {
  padding: 5px;
  border: 1px solid rgb(159 159 159 / 75%);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 30%);
  background-color: rgb(255 255 255);

  small {
    color: gray;
    padding-bottom: 5px;
  }
}
</style>
