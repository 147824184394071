import { reactive } from 'vue'
import { makeFilterHelper } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/userApi'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

export function useUserFilter() {
  return reactive({
    id: {
      ...makeFilter({ name: 'id', variant: 'in' }),
    },
    lastName: {
      ...makeFilter({ name: 'lastName', variant: 'startsWith', field: 'person.lastName' }),
    },
  })
}

export function useTaskUserFilter() {
  return reactive({
    id: {
      ...makeFilter({ name: 'id', variant: 'in' }),
    },
    lastName: {
      ...makeFilter({ name: 'lastName', variant: 'startsWith', field: 'person.lastName' }),
    },
    deskId: {
      ...makeFilter({ name: 'deskId', variant: 'custom' }),
    },
  })
}
