<script lang="ts" setup>
import { useEmbedRelatedDialogItemArticleValidation } from '@/components/anzutap/nodes/embedRelated/composables/embedRelatedDialogValidations'
import { ROUTE } from '@/router/routes'
import {
  type RelatedItemDetailKindArticle,
  type RelatedItemDetailKindLink,
  RelatedItemDiscriminator,
  type RelatedItemKindArticle,
} from '@/types/cms/RelatedItem'
import { AFormTextarea, AImageWidgetSimple, isInt, isUndefined, type ValidationScope } from '@anzusystems/common-admin'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    modelValue: RelatedItemKindArticle
    detailItems: Array<RelatedItemDetailKindArticle | RelatedItemDetailKindLink> | undefined
    hideImage?: boolean
    showDetailLink?: boolean
    error?: undefined | string
    validationScope?: ValidationScope
  }>(),
  {
    hideImage: false,
    showDetailLink: false,
    error: undefined,
    validationScope: undefined,
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', data: RelatedItemKindArticle): void
}>()

const itemComputed = computed({
  get() {
    return props.modelValue
  },
  set(newValue: RelatedItemKindArticle) {
    emit('update:modelValue', newValue)
  },
})

const detail = computed(() => {
  if (isUndefined(props.detailItems)) return undefined
  return props.detailItems.find((item) => {
    return (
      item.discriminator === RelatedItemDiscriminator.Article && item.article.docId === itemComputed.value.articleDocId
    )
  }) as RelatedItemDetailKindArticle | undefined
})

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const { v$ } = useEmbedRelatedDialogItemArticleValidation(itemComputed, props.validationScope)

const { t } = useI18n()
</script>

<template>
  <div
    v-if="detail"
    class="d-flex align-center justify-space-between"
  >
    <div
      v-if="!hideImage"
      class="mr-2"
      style="width: 200px"
    >
      <AImageWidgetSimple
        v-if="detail.article.image"
        :model-value="isInt(detail.article.image) ? detail.article.image : detail.article.image.id"
        :image="isInt(detail.article.image) ? undefined : detail.article.image"
      />
    </div>
    <div class="w-100">
      <div
        v-if="error"
        class="text-error mb-2"
      >
        {{ error }}
      </div>
      <div>{{ detail.article.texts.headline }}</div>
      <div class="mt-4">
        <AFormTextarea
          v-model="itemComputed.headline"
          :label="t('editor.node.embedRelated.headlineOverride')"
          :v="v$.item.headline"
        />
      </div>
    </div>
    <div v-if="showDetailLink && detail">
      <VBtn
        icon
        size="x-small"
        variant="text"
        target="_blank"
        rel="noopener noreferrer"
        :to="{ name: ROUTE.CMS.ARTICLE.DETAIL, params: { id: detail.article.id } }"
      >
        <VIcon icon="mdi-launch" />
        <VTooltip
          activator="parent"
          location="bottom"
        >
          Show article
        </VTooltip>
      </VBtn>
    </div>
  </div>
</template>

<style scoped lang="scss">
.border-red {
  border: 1px solid red;
}
</style>
