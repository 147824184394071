import { useI18n } from 'vue-i18n'
import Highcharts from 'highcharts'
import type { PlotLine } from '@/types/highcharts/Highcharts'
import type { MetricGroupByType } from '@/types/owl/Metric'
import { MetricGroupBy } from '@/types/owl/Metric'

export function useHighcharts() {
  const { t } = useI18n()

  const initializeHighcharts = () => {
    Highcharts.setOptions({
      lang: {
        loading: t('highcharts.highcharts.loading'),
        months: [
          t('highcharts.highcharts.months.january'),
          t('highcharts.highcharts.months.february'),
          t('highcharts.highcharts.months.march'),
          t('highcharts.highcharts.months.april'),
          t('highcharts.highcharts.months.may'),
          t('highcharts.highcharts.months.june'),
          t('highcharts.highcharts.months.july'),
          t('highcharts.highcharts.months.august'),
          t('highcharts.highcharts.months.september'),
          t('highcharts.highcharts.months.october'),
          t('highcharts.highcharts.months.november'),
          t('highcharts.highcharts.months.december'),
        ],
        shortMonths: [
          t('highcharts.highcharts.shortMonths.jan'),
          t('highcharts.highcharts.shortMonths.feb'),
          t('highcharts.highcharts.shortMonths.mar'),
          t('highcharts.highcharts.shortMonths.apr'),
          t('highcharts.highcharts.shortMonths.may'),
          t('highcharts.highcharts.shortMonths.jun'),
          t('highcharts.highcharts.shortMonths.jul'),
          t('highcharts.highcharts.shortMonths.aug'),
          t('highcharts.highcharts.shortMonths.sep'),
          t('highcharts.highcharts.shortMonths.oct'),
          t('highcharts.highcharts.shortMonths.nov'),
          t('highcharts.highcharts.shortMonths.dec'),
        ],
        weekdays: [
          t('highcharts.highcharts.weekdays.sunday'),
          t('highcharts.highcharts.weekdays.monday'),
          t('highcharts.highcharts.weekdays.tuesday'),
          t('highcharts.highcharts.weekdays.wednesday'),
          t('highcharts.highcharts.weekdays.thursday'),
          t('highcharts.highcharts.weekdays.friday'),
          t('highcharts.highcharts.weekdays.saturday'),
        ],
        numericSymbols: [
          t('highcharts.highcharts.numericSymbols.k'),
          t('highcharts.highcharts.numericSymbols.m'),
          t('highcharts.highcharts.numericSymbols.g'),
          t('highcharts.highcharts.numericSymbols.t'),
          t('highcharts.highcharts.numericSymbols.p'),
          t('highcharts.highcharts.numericSymbols.e'),
        ],
        decimalPoint: t('highcharts.highcharts.decimalPoint'),
        thousandsSep: t('highcharts.highcharts.thousandsSep'),
        resetZoom: t('highcharts.highcharts.resetZoom'),
        resetZoomTitle: t('highcharts.highcharts.resetZoom'),
        noData: t('highcharts.highcharts.noData'),
      },
    })
  }

  /**
   * Returns nearest Date for current chart scale.
   */
  const getNearestScaleDate = function (date: Date, groupBy: MetricGroupByType, since: Date | undefined): Date {
    const result = new Date(date.valueOf())
    if (since === undefined || groupBy === undefined) {
      return result
    }

    switch (groupBy) {
      case MetricGroupBy.Hour:
        result.setMinutes(0, 0, 0)
        break
      case MetricGroupBy.Day:
        result.setHours(0, 0, 0, 0)
        break
      case MetricGroupBy.Minute:
        result.setSeconds(0, 0)
    }

    return result
  }

  const formatTimeSpent = (seconds: number | undefined): string => {
    if (seconds === undefined) {
      return ''
    }
    const days = Math.floor(seconds / (24 * 3600))
    const hours = Math.floor((seconds % (24 * 3600)) / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secs = seconds % 60

    if (days > 0) {
      return days + 'd ' + hours + 'h'
    } else if (hours > 0) {
      return hours + 'h ' + minutes + 'm'
    } else if (minutes > 0) {
      return minutes + 'm ' + secs + 's'
    } else {
      return secs + 's'
    }
  }

  const levelToColor = (level: number): string => {
    // 1 = Critical, 2 = Major, 3 = Important, 4 = moderate, 5 = Minor, Default: 4 = Moderate
    switch (level) {
      case 1:
        return 'rgb(255 0 0 / 70%)'
      case 2:
        return 'rgb(153 0 215 / 70%)'
      case 3:
        return 'rgb(199 131 0 / 70%)'
      case 4:
        return 'rgb(0 0 255 / 70%)'
      case 5:
        return 'rgb(0 128 0 / 70%)'
      default:
        return 'rgb(94 94 94 / 70%)'
    }
  }

  /**
   * Translate versioned string, e.g. "Published v.42"
   */
  const translateVersionedString = (text: string): string => {
    const idx = text.lastIndexOf('v.')
    if (idx === -1) {
      return t(text)
    }

    const textBeforeVersion = text.substring(0, idx).trim()
    const textAfterVersion = text.substring(idx)
    const translatedText = t(textBeforeVersion)

    return translatedText + ' ' + textAfterVersion
  }

  const trans = (key: string, fallback: string): string => {
    const result = translateVersionedString(key)
    if (result !== key) {
      return result
    }

    return fallback
  }

  const addPlotLine = (chart: Highcharts.Chart, chartOptions: Highcharts.Options, plotLineDef: PlotLine): void => {
    const levelColor = levelToColor(plotLineDef.level)
    const title = trans('highcharts.highcharts.plotLine.' + plotLineDef.title, plotLineDef.title)
    const description = plotLineDef.description
    const plotLineValue = plotLineDef.chartDate.getTime()

    plotLineDef.tooltipElement = chart.renderer
      .label(
        '<div class="chart-plot-line-tooltip-label"><small>' +
          `${chart.time.dateFormat('%A, %b %e, %Y, %H:%M', plotLineDef.date.getTime())}` +
          '</small><br>' +
          `<span style="color: ${levelColor}">\u25CF</span>` +
          `<strong>${title}</strong>` +
          `<br>${description}</div>`,
        0,
        0,
        'rect',
        0,
        0,
        true
      )
      .css({
        color: '#000000',
        fontSize: '0.8rem',
      })
      .attr({
        zIndex: 5,
      })
      .hide()
      .add()

    const xAxis = chartOptions.xAxis as Highcharts.XAxisOptions
    if (xAxis.plotLines === undefined) {
      return
    }
    xAxis.plotLines.push({
      id: plotLineDef.id,
      value: plotLineValue,
      width: 3,
      color: levelColor,
      dashStyle: 'ShortDash',
      zIndex: 1,
      label: {
        text: title,
        align: 'left',
        y: 0,
        style: {
          color: levelColor,
          zIndex: 10,
        },
      },
      events: {
        mouseover: function () {
          if (null === plotLineDef.tooltipElement) {
            return
          }
          const plotLineX = chart.xAxis[0].toPixels(plotLineValue)
          const tooltipX = plotLineX + 15
          const tooltipY = chart.plotTop + 20

          plotLineDef.tooltipElement
            .attr({
              x: tooltipX,
              y: tooltipY,
            })
            .show()
        },
        mouseout: function () {
          if (null === plotLineDef.tooltipElement) {
            return
          }
          plotLineDef.tooltipElement.hide()
        },
      },
    })
  }

  const removePlotLines = (chart: Highcharts.Chart, plotlines: PlotLine[]): void => {
    const yAxis = chart.yAxis[0]

    for (const plotline of plotlines) {
      if (null === plotline.tooltipElement) {
        continue
      }
      plotline.tooltipElement.destroy()
      plotline.tooltipElement = null

      yAxis.removePlotLine(plotline.id)
    }
  }

  const defaultLineChartOptions: Highcharts.Options = {
    time: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: '',
      align: 'left',
    },
    chart: {
      type: 'line',
      zooming: {
        type: 'x',
      },
      panning: {
        enabled: true,
      },
      panKey: 'shift',
    },
    legend: {
      align: 'left',
      verticalAlign: 'top',
      borderWidth: 0,
    },
  }

  return {
    initializeHighcharts,
    formatTimeSpent,
    addPlotLine,
    removePlotLines,
    getNearestScaleDate,
    defaultLineChartOptions,
  }
}
