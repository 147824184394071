import { ArticleDiscriminator } from '@/model/cms/valueObject/ArticleDiscriminator'
import { ArticleResourceName } from '@/model/cms/valueObject/ArticleResourceName'
import type { ArticleStandardIconType } from '@/model/cms/valueObject/ArticleStandardIcon'
import type { ArticleStandardLockTypeType } from '@/model/cms/valueObject/ArticleStandardLockType'
import type { ArticleFlags, ArticleKind, ArticleKindAware } from '@/types/cms/ArticleKind/ArticleKind'
import type { Page } from '@/types/cms/Page'
import type { Person } from '@/types/cms/Person'
import type { GeoLatitude, GeoLongitude } from '@/types/common'
import type { DocId, IntegerId, IntegerIdNullable } from '@anzusystems/common-admin'

export type ArticleKindStandardWithVersionData = ArticleKindStandard & {
  versionsData?: ArticleKindStandard
}

export interface ArticleKindStandard extends ArticleKindAware {
  discriminator: typeof ArticleDiscriminator.Standard
  gallery: IntegerIdNullable
  intention: IntegerIdNullable
  attributesStandard: ArticleAttributesStandard
  flagsStandard: ArticleFlagsStandard
  activeStages: IntegerId[]
  completedStages: IntegerId[]
  desk: IntegerId
  relatedToMe: DocId[]
  relatedArticles: DocId[]
  mainPage: IntegerIdNullable
  newsletter: IntegerIdNullable
  canonicalUrl: string
  _resourceName: typeof ArticleResourceName.Standard
}

export interface ArticleAttributesStandard {
  inTrending: boolean
  bodyTextCharCount: number
  icon: ArticleStandardIconType
  geolocation: {
    latitude: GeoLatitude
    longitude: GeoLongitude
  }
  lockType: ArticleStandardLockTypeType
  smsCode: null | string
  publicVisibleBodyCharCount: number
}

export interface ArticleFlagsStandard extends ArticleFlags {
  enableForum: boolean
  enableMultiTitleTest: boolean
  prAuthor: boolean
}

export const isArticleKindStandard = (article: ArticleKind): article is ArticleKindStandard => {
  return article.discriminator === ArticleDiscriminator.Standard && Object.hasOwn(article, 'attributesStandard')
}

export interface ArticleStagesChangeCollabUpdate {
  action: 'change'
  data: {
    activeStages: IntegerId[]
    completedStages: IntegerId[]
  }
}

export interface ArticleRelatedEntities {
  person: Person | null
  pages: Page[]
}
