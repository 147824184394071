import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import {
  apiAnyRequest,
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
  type FilterBag,
  type IntegerId,
  type Pagination,
} from '@anzusystems/common-admin'
import type { Desk } from '@/types/cms/Desk'
import type { Page, PageDuplicateDto } from '@/types/cms/Page'
import { ENTITY as PAGE_ENTITY } from '@/services/api/cms/pageApi'

const END_POINT = '/adm/desks'
export const ENTITY = 'desk'

export const fetchDeskList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<Desk[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchDeskListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<Desk[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchDesk = (id: IntegerId) => apiFetchOne<Desk>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createDesk = (data: Desk) => apiCreateOne<Desk>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateDesk = (id: IntegerId, data: Desk) =>
  apiUpdateOne<Desk>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteDesk = (id: IntegerId) =>
  apiDeleteOne<Desk>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createPage = (deskId: IntegerId, data: Page) =>
  apiCreateOne<Page>(cmsClient, data, END_POINT + '/:id/page', { id: deskId }, SYSTEM_CMS, PAGE_ENTITY)

export const duplicatePage = (deskId: IntegerId, duplicateDto: PageDuplicateDto) =>
  apiAnyRequest<PageDuplicateDto, Page>(
    cmsClient,
    'PATCH',
    END_POINT + '/:deskId/duplicate/:pageId',
    { deskId: deskId, pageId: duplicateDto.page as IntegerId },
    duplicateDto,
    SYSTEM_CMS,
    PAGE_ENTITY
  )
