import { isArray, isEmpty, isUndefined } from '@anzusystems/common-admin'
import type { JSONContent } from '@tiptap/core'

export function useDocumentFactory() {
  const createEmptyDocument = (): JSONContent => {
    return {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [],
        },
      ],
    }
  }

  return {
    createEmptyDocument,
  }
}

export function checkForEmptyDocument(content: JSONContent | undefined) {
  const { createEmptyDocument } = useDocumentFactory()
  if (
    isUndefined(content) ||
    isEmpty(content) ||
    isUndefined(content.content) ||
    (isArray(content.content) && content.content.length === 0)
  ) {
    content = createEmptyDocument()
  }
  return content
}
