<script lang="ts" setup>
import {
  ACopyText,
  AFormTextarea,
  AFormTextField,
  AFormValueObjectOptionsSelect,
  ARow,
  ASystemEntityScope,
} from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { useI18n } from 'vue-i18n'
import { ENTITY } from '@/services/api/cms/newsletterApi'
import { useNewsletterCreateEditActions } from '@/views/cms/newsletter/composables/newsletterActions'
import { useNewsletterType } from '@/model/cms/valueObject/NewsletterType'
import SiteGroupSiteRubricSelect from '@/components/cms/SiteGroupSiteRubricSelect.vue'
import { useNewsletterValidation } from '@/views/cms/newsletter/composables/newsletterValidation'
import { ref } from 'vue'
import NewsletterSchedules from '@/views/cms/newsletterSchedule/components/NewsletterSchedules.vue'
import NewsletterGeneratorSettings from '@/views/cms/newsletter/components/NewsletterGeneratorSettings.vue'

withDefaults(
  defineProps<{
    isEdit?: boolean
  }>(),
  {
    isEdit: false,
  }
)
const { newsletter } = useNewsletterCreateEditActions()
const rightTab = ref('schedules')

const { v$ } = useNewsletterValidation(newsletter)
const { t } = useI18n()

const { newsletterTypeOptions } = useNewsletterType()
</script>

<template>
  <ASystemEntityScope
    :system="SYSTEM_CMS"
    :subject="ENTITY"
  >
    <VRow>
      <VCol cols="8">
        <ARow :title="t('cms.newsletter.model.id')">
          <ACopyText :value="newsletter.id" />
        </ARow>
        <ARow>
          <AFormTextarea
            v-model="newsletter.texts.title"
            :v="v$.newsletter.texts.title"
          />
        </ARow>
        <ARow>
          <AFormTextarea
            v-model="newsletter.texts.titlePublic"
            :v="v$.newsletter.texts.titlePublic"
          />
        </ARow>
        <ARow>
          <SiteGroupSiteRubricSelect
            v-model:site-group="newsletter.siteGroup"
            v-model:site="newsletter.site"
            v-model:rubric="newsletter.rubric"
            :readonly="false"
            :label-site-group="t('cms.newsletter.model.siteGroup')"
            :label-site="t('cms.newsletter.model.site')"
            :label-rubric="t('cms.newsletter.model.rubric')"
            :v-site-group="v$.newsletter.siteGroup"
            :v-site="v$.newsletter.site"
            :v-rubric="v$.newsletter.rubric"
          />
        </ARow>
        <ARow>
          <AFormTextarea
            v-model="newsletter.mailerId"
            :v="v$.newsletter.mailerId"
          />
        </ARow>
        <ARow :title="t('cms.newsletter.model.newsletterType')">
          <AFormValueObjectOptionsSelect
            v-model="newsletter.type"
            :items="newsletterTypeOptions"
          />
        </ARow>
        <ARow>
          <VSwitch
            v-model="newsletter.enabled"
            :label="t('cms.newsletter.model.enabled')"
            hide-details
          />
        </ARow>
      </VCol>
      <VCol cols="4">
        <VTabs
          v-model="rightTab"
          color="primary"
          class="mb-2"
          grow
        >
          <VTab value="schedules">
            Plánovače
          </VTab>
          <VTab value="mailerSetting">
            Mailer config
          </VTab>
        </VTabs>
        <div v-show="'schedules' === rightTab">
          <NewsletterSchedules
            v-if="newsletter.id"
            :newsletter="newsletter"
          />
        </div>
        <div v-show="'mailerSetting' === rightTab">
          <NewsletterGeneratorSettings
            v-if="newsletter.id"
            :newsletter="newsletter"
          />
          <ARow>
            <AFormTextField
              v-model="newsletter.settings.emailTemplateMailLayoutId"
              :label="t('cms.newsletter.model.settings.emailTemplateMailLayoutId')"
            />
          </ARow>
          <ARow>
            <AFormTextarea
              v-model="newsletter.settings.emailTemplateMailTypeCode"
              :label="t('cms.newsletter.model.settings.emailTemplateMailTypeCode')"
            />
          </ARow>
          <ARow>
            <AFormTextarea
              v-model="newsletter.settings.emailTemplateFrom"
              :label="t('cms.newsletter.model.settings.emailTemplateFrom')"
            />
          </ARow>
          <ARow>
            <AFormTextarea
              v-model="newsletter.settings.emailTemplateSubject"
              :label="t('cms.newsletter.model.settings.emailTemplateSubject')"
            />
          </ARow>
          <ARow>
            <AFormTextarea
              v-model="newsletter.settings.emailTemplateSubjectDynamicParam"
              :label="t('cms.newsletter.model.settings.emailTemplateSubjectDynamicParam')"
            />
          </ARow>
          <ARow>
            <AFormTextarea
              v-model="newsletter.settings.segmentProvider"
              :label="t('cms.newsletter.model.settings.segmentProvider')"
            />
          </ARow>
          <ARow>
            <AFormTextarea
              v-model="newsletter.settings.segmentCode"
              :label="t('cms.newsletter.model.settings.segmentCode')"
            />
          </ARow>
          <ARow>
            <AFormTextarea
              v-model="newsletter.settings.segmentCodeWithWebAccess"
              :label="t('cms.newsletter.model.settings.segmentCodeWithWebAccess')"
            />
          </ARow>
          <ARow>
            <AFormTextarea
              v-model="newsletter.settings.segmentCodeWithoutWebAccess"
              :label="t('cms.newsletter.model.settings.segmentCodeWithoutWebAccess')"
            />
          </ARow>
        </div>
      </VCol>
    </VRow>
  </ASystemEntityScope>
</template>
