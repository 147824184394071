import { acceptHMRUpdate, defineStore } from 'pinia'
import { useStageFactory } from '@/model/cms/factory/StageFactory'
import type { Stage } from '@/types/cms/Stage'
import { ref } from 'vue'
import type { IntegerId } from '@anzusystems/common-admin'

export const useStageOneStore = defineStore('cmsStageOneStore', () => {
  const { createDefault } = useStageFactory()

  const stage = ref<Stage>(createDefault())

  function reset() {
    stage.value = createDefault()
  }

  return {
    stage,
    reset,
  }
})

export const useStageAllStore = defineStore('cmsStageAllStore', () => {
  const loaded = ref(false)
  const stages = ref(new Map<IntegerId, Stage>())

  function setStages(values: Stage[]) {
    values.forEach((value) => {
      addStage(value)
    })
  }

  function addStage(value: Stage) {
    stages.value.set(value.id, value)
  }

  function getStage(id: IntegerId) {
    return stages.value.get(id)
  }

  function reset() {
    stages.value.clear()
  }

  return {
    loaded,
    stages,
    setStages,
    getStage,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStageOneStore, import.meta.hot))
  import.meta.hot.accept(acceptHMRUpdate(useStageAllStore, import.meta.hot))
}
