<script lang="ts" setup>
import {
  ArticleDiscriminatorDefault,
  type ArticleDiscriminatorType,
} from '@/model/cms/valueObject/ArticleDiscriminator'
import { fetchArticleKindList } from '@/services/api/cms/articleKindApi'
import type { ArticleKind } from '@/types/cms/ArticleKind/ArticleKind'
import ArticleDiscriminatorChip from '@/views/cms/article/components/ArticleDiscriminatorChip.vue'
import ArticleSelectFilter from '@/views/cms/article/components/ArticleSelectFilter.vue'
import ArticleStatusChip from '@/views/cms/article/components/ArticleStatusChip.vue'
import { useArticleSelectStore } from '@/views/cms/article/composables/articleSelectStore'
import CachedRubricChip from '@/views/cms/rubric/components/CachedRubricChip.vue'
import { useCachedRubrics } from '@/views/cms/rubric/composables/cachedRubrics'
import CachedSiteChip from '@/views/cms/site/components/CachedSiteChip.vue'
import { useCachedSites } from '@/views/cms/site/composables/cachedSites'
import {
  ADatatableConfigButton,
  ADatatableOrdering,
  ADatetime,
  ASubjectSelect,
  type DatatableOrderingOptions,
  type FilterBag,
  generateDatatableMinMaxSelectStrategy,
  type IntegerId,
  type IntegerIdNullable,
  type Pagination,
  useSubjectSelect,
} from '@anzusystems/common-admin'
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    discriminator?: ArticleDiscriminatorType
    keywords?: IntegerId[]
    siteGroup?: IntegerIdNullable
    minCount?: number
    maxCount?: number
  }>(),
  {
    discriminator: ArticleDiscriminatorDefault,
    keywords: () => [],
    siteGroup: null,
    minCount: 1,
    maxCount: 1,
  }
)
const emit = defineEmits<{
  (e: 'onConfirm', data: Array<ArticleKind>): void
}>()

const { datatableHiddenColumns, filter } = useArticleSelectStore()

const fetchArticleKindListCustom = (pagination: Pagination, filterBag: FilterBag) => {
  if (filter.text.model) {
    pagination.sortBy = null
    pagination.descending = true
    customSortModel.value = 3
  } else if (!filter.text.model) {
    pagination.sortBy = 'customSortDate'
    pagination.descending = true
    customSortModel.value = 1
  }
  return fetchArticleKindList(pagination, filterBag)
}

const {
  items,
  selected,
  pagination,
  columnsVisible,
  columnsAll,
  columnsHidden,
  submitFilter,
  resetFilter,
  filterTouched,
  onFetchNextPage,
  onOpen,
  sortByChange,
  getList,
  onRowClick,
  loading,
} = useSubjectSelect<ArticleKind>(
  [
    { key: 'id' },
    { key: 'discriminator' },
    { key: 'docId' },
    { key: 'texts.headline' },
    { key: 'seo.title' },
    { key: 'site' },
    { key: 'rubric' },
    { key: 'status' },
    { key: 'dates.publicPublishedAt' },
    { key: 'dates.expireAt' },
    { key: 'dates.publishedAt' },
    { key: 'createdAt' },
    { key: 'modifiedAt' },
  ],
  datatableHiddenColumns,
  'cms',
  'articleKind',
  fetchArticleKindListCustom,
  filter
)
pagination.sortBy = 'customSortDate'
const customSortModel = ref(1)

const resetFilterCustom = () => {
  pagination.sortBy = 'customSortDate'
  pagination.descending = true
  customSortModel.value = 1
  resetFilter()
}

const customSort: DatatableOrderingOptions = [
  { id: 3, titleT: 'common.system.datatable.ordering.mostRelevant' },
  { id: 1, titleT: 'common.system.datatable.ordering.mostRecent', sortBy: { key: 'customSortDate', order: 'desc' } },
  { id: 2, titleT: 'common.system.datatable.ordering.oldest', sortBy: { key: 'customSortDate', order: 'asc' } },
]

const { addToCachedRubrics, fetchCachedRubrics } = useCachedRubrics()
const { addToCachedSites, fetchCachedSites } = useCachedSites()

watch(
  items,
  (newValue) => {
    if (newValue.length > 0) {
      addToCachedRubrics(newValue.map((article) => article.rubric))
      addToCachedSites(newValue.map((article) => article.site))
      fetchCachedRubrics()
      fetchCachedSites()
    }
  },
  { immediate: true }
)

const onConfirm = (items: Array<ArticleKind>) => {
  emit('onConfirm', items)
}

const { t } = useI18n()
// @ts-ignore
const subjectSelectComponent = ref<InstanceType<typeof ASubjectSelect> | null>(null)

onMounted(() => {
  filter.discriminator.model = props.discriminator
  filter.keywords.model = props.keywords
  filter.siteGroup.model = props.siteGroup
})

defineExpose({
  open: () => {
    subjectSelectComponent.value?.open()
  },
})
</script>

<template>
  <ASubjectSelect
    ref="subjectSelectComponent"
    v-model:pagination="pagination"
    :min-count="minCount"
    :max-count="maxCount"
    :selected-items="selected"
    :submit-filter="submitFilter"
    :reset-filter="resetFilterCustom"
    :loading="loading"
    dialog-title-t="cms.articleKind.meta.select"
    @on-open="onOpen"
    @on-fetch-next-page="onFetchNextPage"
    @on-page-change="getList"
    @on-confirm="onConfirm"
  >
    <template #activator="{ props: activatorProps }">
      <slot
        name="activator"
        :props="activatorProps"
      >
        <VBtn
          variant="text"
          v-bind="activatorProps"
        >
          {{ t('cms.article.meta.select') }}
        </VBtn>
      </slot>
    </template>
    <template #filter>
      <ArticleSelectFilter
        v-model:touched="filterTouched"
        @submit-filter="submitFilter"
      />
    </template>
    <template #second-bar-right>
      <ADatatableOrdering
        v-model="customSortModel"
        :custom-options="customSort"
        @sort-by-change="sortByChange"
      />
      <ADatatableConfigButton
        v-model:columns-hidden="columnsHidden"
        :columns-all="columnsAll"
      />
    </template>
    <template #content>
      <VDataTableServer
        v-model="selected"
        show-select
        class="a-datatable"
        :headers="columnsVisible"
        :items="items"
        :items-length="items.length"
        :loading="loading"
        item-value="id"
        return-object
        :select-strategy="generateDatatableMinMaxSelectStrategy(minCount, maxCount) as any"
        @click:row.stop="onRowClick"
      >
        <template #item.data-table-select="{ internalItem, toggleSelect, isSelected }">
          <VCheckboxBtn
            :disabled="!internalItem.selectable"
            :model-value="isSelected([internalItem])"
            @click.prevent="toggleSelect(internalItem)"
            @change="toggleSelect(internalItem)"
          />
        </template>
        <template #item.discriminator="{ item }: { item: ArticleKind }">
          <ArticleDiscriminatorChip :discriminator="item.discriminator" />
        </template>
        <template #item.texts.headline="{ item }: { item: ArticleKind }">
          <div class="line-clamp-2">
            {{ item.texts.headline }}
          </div>
        </template>
        <template #item.seo.title="{ item }: { item: ArticleKind }">
          <div class="line-clamp-2">
            {{ item.seo.title }}
          </div>
        </template>
        <template #item.site="{ item }: { item: ArticleKind }">
          <CachedSiteChip :id="item.site" />
        </template>
        <template #item.rubric="{ item }: { item: ArticleKind }">
          <CachedRubricChip :id="item.rubric" />
        </template>
        <template #item.status="{ item }: { item: ArticleKind }">
          <ArticleStatusChip :status="item.status" />
        </template>
        <template #item.dates.publicPublishedAt="{ item }: { item: ArticleKind }">
          <ADatetime :date-time="item.dates.publicPublishedAt" />
        </template>
        <template #item.dates.expireAt="{ item }: { item: ArticleKind }">
          <ADatetime :date-time="item.dates.expireAt" />
        </template>
        <template #item.createdAt="{ item }: { item: ArticleKind }">
          <ADatetime :date-time="item.createdAt" />
        </template>
        <template #item.modifiedAt="{ item }: { item: ArticleKind }">
          <ADatetime :date-time="item.modifiedAt" />
        </template>
        <template #bottom />
      </VDataTableServer>
    </template>
  </ASubjectSelect>
</template>
