import { isNull, type ValueObjectOption } from '@anzusystems/common-admin'
import type { Embed, EmbedAware } from '@/components/anzutap/types/Embed'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'

export const isEmbedExternal = (embed: EmbedAware | Embed | null): embed is EmbedExternalAware => {
  if (isNull(embed)) return false
  return embed.discriminator === 'external'
}

export interface EmbedExternalAware extends EmbedAware {
  discriminator: 'external'
  params: Record<string, any>
  data: Record<string, any>
  type: ScrapeTypeType
  scrapeStatus: ScrapeStatusType
}

export interface EmbedExternalCreateUpdateDto
  extends Omit<
    EmbedExternalAware,
    'detail' | 'discriminator' | '_resourceName' | '_system' | 'createdAt' | 'modifiedAt' | 'createdBy' | 'modifiedBy'
  > {}

export const ScrapeStatus = {
  Unassigned: 'unassigned',
  Pending: 'pending',
  Done: 'done',
  Error: 'error',
} as const
export const ScrapeStatusDefault = ScrapeStatus.Unassigned
export type ScrapeStatusType = (typeof ScrapeStatus)[keyof typeof ScrapeStatus]

export function useEmbedExternalScrapeStatus() {
  const { t } = useI18n()

  const embedExternalScrapeStatusOptions = ref<ValueObjectOption<ScrapeStatusType>[]>([
    {
      value: ScrapeStatus.Unassigned,
      title: t('editor.node.embedExternal.scrapeStatus.unassigned'),
    },
    {
      value: ScrapeStatus.Pending,
      title: t('editor.node.embedExternal.scrapeStatus.pending'),
    },
    {
      value: ScrapeStatus.Done,
      title: t('editor.node.embedExternal.scrapeStatus.done'),
    },
    {
      value: ScrapeStatus.Error,
      title: t('editor.node.embedExternal.scrapeStatus.error'),
    },
  ])

  const getEmbedExternalScrapeStatusOption = (value: ScrapeStatusType) => {
    return embedExternalScrapeStatusOptions.value.find((item) => item.value === value)
  }

  return {
    embedExternalScrapeStatusOptions,
    getEmbedExternalScrapeStatusOption,
  }
}

export const ScrapeType = {
  DailymotionVideo: 'dailymotion_video',
  FacebookGallery: 'facebook_gallery',
  FacebookPhoto: 'facebook_photo',
  FacebookPost: 'facebook_post',
  FacebookReel: 'facebook_reel',
  FacebookVideo: 'facebook_video',
  FlourishStory: 'flourish_story',
  FlourishVisual: 'flourish_visual',
  GiphyAnimation: 'giphy_animation',
  GoogleDocument: 'google_document',
  GoogleForm: 'google_form',
  GoogleMap: 'google_map',
  GoogleMymap: 'google_mymap',
  GoogleSpreadsheet: 'google_spreadsheet',
  IdnesVideo: 'idnes_video',
  IframeBasic: 'iframe_basic',
  InstagramPost: 'instagram_post',
  InstagramReel: 'instagram_reel',
  JwVideo: 'jw_video',
  KnightlabJuxtapose: 'knightlab_juxtapose',
  PinterestPin: 'pinterest_pin',
  PodbeanEpisode: 'podbean_episode',
  ScribdDocument: 'scribd_document',
  SeznamMap: 'seznam_map',
  SlidoEvent: 'slido_event',
  SlidoPoll: 'slido_poll',
  SoundcloudTrack: 'soundcloud_track',
  SpotifyEpisode: 'spotify_episode',
  Ta3Video: 'ta3_video',
  TableAu: 'tableau_visual',
  TelegramPost: 'telegram_post',
  TiktokVideo: 'tiktok_video',
  TwitterPost: 'twitter_post',
  TwitterVideo: 'twitter_video',
  VimeoVideo: 'vimeo_video',
  YoutubeVideo: 'youtube_video',
} as const
export const ScrapeTypeDefault = ScrapeType.IframeBasic
export type ScrapeTypeType = (typeof ScrapeType)[keyof typeof ScrapeType]

export function useEmbedExternalScrapeType() {
  const { t } = useI18n()

  const embedExternalScrapeTypeOptions = ref<ValueObjectOption<ScrapeTypeType>[]>([
    {
      value: ScrapeType.DailymotionVideo,
      title: t('editor.node.embedExternal.type.dailymotionVideo'),
    },
    {
      value: ScrapeType.FacebookGallery,
      title: t('editor.node.embedExternal.type.facebookGallery'),
    },
    {
      value: ScrapeType.FacebookPhoto,
      title: t('editor.node.embedExternal.type.facebookPhoto'),
    },
    {
      value: ScrapeType.FacebookPost,
      title: t('editor.node.embedExternal.type.facebookPost'),
    },
    {
      value: ScrapeType.FacebookVideo,
      title: t('editor.node.embedExternal.type.facebookVideo'),
    },
    {
      value: ScrapeType.FlourishStory,
      title: t('editor.node.embedExternal.type.flourishStory'),
    },
    {
      value: ScrapeType.FlourishVisual,
      title: t('editor.node.embedExternal.type.flourishVisual'),
    },
    {
      value: ScrapeType.GiphyAnimation,
      title: t('editor.node.embedExternal.type.giphyAnimation'),
    },
    {
      value: ScrapeType.GoogleDocument,
      title: t('editor.node.embedExternal.type.googleDocument'),
    },
    {
      value: ScrapeType.GoogleForm,
      title: t('editor.node.embedExternal.type.googleForm'),
    },
    {
      value: ScrapeType.GoogleMap,
      title: t('editor.node.embedExternal.type.googleMap'),
    },
    {
      value: ScrapeType.GoogleMymap,
      title: t('editor.node.embedExternal.type.googleMymap'),
    },
    {
      value: ScrapeType.GoogleSpreadsheet,
      title: t('editor.node.embedExternal.type.googleSpreadsheet'),
    },
    {
      value: ScrapeType.IdnesVideo,
      title: t('editor.node.embedExternal.type.idnesVideo'),
    },
    {
      value: ScrapeType.IframeBasic,
      title: t('editor.node.embedExternal.type.iframeBasic'),
    },
    {
      value: ScrapeType.InstagramPost,
      title: t('editor.node.embedExternal.type.instagramPost'),
    },
    {
      value: ScrapeType.InstagramReel,
      title: t('editor.node.embedExternal.type.instagramReel'),
    },
    {
      value: ScrapeType.JwVideo,
      title: t('editor.node.embedExternal.type.jwVideo'),
    },
    {
      value: ScrapeType.KnightlabJuxtapose,
      title: t('editor.node.embedExternal.type.knightlabJuxtapose'),
    },
    {
      value: ScrapeType.PinterestPin,
      title: t('editor.node.embedExternal.type.pinterestPin'),
    },
    {
      value: ScrapeType.PodbeanEpisode,
      title: t('editor.node.embedExternal.type.podbeanEpisode'),
    },
    {
      value: ScrapeType.ScribdDocument,
      title: t('editor.node.embedExternal.type.scribdDocument'),
    },
    {
      value: ScrapeType.SeznamMap,
      title: t('editor.node.embedExternal.type.seznamMap'),
    },
    {
      value: ScrapeType.SlidoEvent,
      title: t('editor.node.embedExternal.type.slidoEvent'),
    },
    {
      value: ScrapeType.SlidoPoll,
      title: t('editor.node.embedExternal.type.slidoPoll'),
    },
    {
      value: ScrapeType.SoundcloudTrack,
      title: t('editor.node.embedExternal.type.soundcloudTrack'),
    },
    {
      value: ScrapeType.SpotifyEpisode,
      title: t('editor.node.embedExternal.type.spotifyEpisode'),
    },
    {
      value: ScrapeType.Ta3Video,
      title: t('editor.node.embedExternal.type.ta3Video'),
    },
    {
      value: ScrapeType.TiktokVideo,
      title: t('editor.node.embedExternal.type.tiktokVideo'),
    },
    {
      value: ScrapeType.TwitterPost,
      title: t('editor.node.embedExternal.type.twitterPost'),
    },
    {
      value: ScrapeType.TwitterVideo,
      title: t('editor.node.embedExternal.type.twitterVideo'),
    },
    {
      value: ScrapeType.VimeoVideo,
      title: t('editor.node.embedExternal.type.vimeoVideo'),
    },
    {
      value: ScrapeType.YoutubeVideo,
      title: t('editor.node.embedExternal.type.youtubeVideo'),
    },
    {
      value: ScrapeType.TableAu,
      title: t('editor.node.embedExternal.type.tableAu'),
    },
  ])

  const getEmbedExternalScrapeTypeOption = (value: ScrapeTypeType) => {
    return embedExternalScrapeTypeOptions.value.find((item) => item.value === value)
  }

  return {
    embedExternalScrapeTypeOptions,
    getEmbedExternalScrapeTypeOption,
  }
}
