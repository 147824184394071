import useVuelidate from '@vuelidate/core'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import {
  createNewsletterPromo,
  deleteNewsletterPromo,
  fetchNewsletterPromo,
  fetchNewsletterPromoList,
  fetchNewsletterPromoListByIds,
  updateNewsletterPromo,
} from '@/services/api/cms/newsletterPromoApi'
import { ROUTE } from '@/router/routes'
import { useNewsletterPromoOneStore } from '@/stores/cms/newsletterPromoStore'
import { ref } from 'vue'
import type { FilterBag, IntegerId, Pagination, ValueObjectOption } from '@anzusystems/common-admin'
import { useAlerts } from '@anzusystems/common-admin'
import type { NewsletterPromo } from '@/types/cms/NewsletterPromo'
import { useCachedNewsletters } from '@/views/cms/newsletter/composables/cachedNewsletters'
import { NewsletterPromoValidationSymbol } from '@/views/cms/newsletterPromo/composables/newsletterPromoValidation'

const { showValidationError, showRecordWas, showErrorsDefault } = useAlerts()
const { addToCachedNewsletters, fetchCachedNewsletters } = useCachedNewsletters()

const datatableHiddenColumns = ref<Array<string>>([])
const listLoading = ref(false)
const detailLoading = ref(false)
const saveButtonLoading = ref(false)

export const useNewsletterPromoSelectActions = () => {
  const fetchItems = async (pagination: Pagination, filterBag: FilterBag) => {
    const newsletterPromoList = await fetchNewsletterPromoList(pagination, filterBag)

    return <ValueObjectOption<IntegerId>[]>newsletterPromoList.map((newsletterPromo: NewsletterPromo) => ({
      title: newsletterPromo.texts.title,
      value: newsletterPromo.id,
    }))
  }

  const fetchItemsByIds = async (ids: number[]) => {
    const newsletterPromoList = await fetchNewsletterPromoListByIds(ids)
    return <ValueObjectOption<IntegerId>[]>newsletterPromoList.map((newsletterPromo: NewsletterPromo) => ({
      value: newsletterPromo.id,
      title: newsletterPromo.texts.title,
    }))
  }

  return {
    fetchItems,
    fetchItemsByIds,
  }
}

export const useNewsletterPromoDetailActions = () => {
  const newsletterPromoOneStore = useNewsletterPromoOneStore()
  const { newsletterPromo, newsletterPromoLoaded } = storeToRefs(newsletterPromoOneStore)

  const fetchData = async (id: number) => {
    detailLoading.value = true
    try {
      const newsletterPromoRes = await fetchNewsletterPromo(id)
      newsletterPromoOneStore.setNewsletterPromo(newsletterPromoRes)
      addToCachedNewsletters(newsletterPromoRes.newsletters)
      fetchCachedNewsletters()
      newsletterPromoLoaded.value = true
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  return {
    newsletterPromo,
    detailLoading,
    fetchData,
    resetStore: newsletterPromoOneStore.reset,
  }
}

export const useNewsletterPromoDeleteActions = () => {
  const router = useRouter()

  const onDelete = async (id: number) => {
    try {
      await deleteNewsletterPromo(id)
      showRecordWas('deleted')
      router.push({ name: ROUTE.CMS.NEWSLETTER_PROMO.LIST })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      // closeDeleteDialog()
    }
  }

  return {
    onDelete,
  }
}

export const useNewsletterPromoCreateEditActions = () => {
  const v$ = useVuelidate({ $scope: NewsletterPromoValidationSymbol })
  const router = useRouter()
  const newsletterPromoOneStore = useNewsletterPromoOneStore()
  const { newsletterPromo, newsletterPromoLoaded } = storeToRefs(newsletterPromoOneStore)

  const fetchData = async (id: number) => {
    detailLoading.value = true
    try {
      const newsletterPromoRes = await fetchNewsletterPromo(id)
      newsletterPromoOneStore.setNewsletterPromo(newsletterPromoRes)
      newsletterPromoLoaded.value = true
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  const onUpdate = async (close = false) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      await updateNewsletterPromo(newsletterPromoOneStore.newsletterPromo.id, newsletterPromoOneStore.newsletterPromo)
      showRecordWas('updated')
      if (!close) return
      router.push({ name: ROUTE.CMS.NEWSLETTER_PROMO.LIST })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  const onCreate = async (close = false) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      const newsletterPromoRes = await createNewsletterPromo(newsletterPromoOneStore.newsletterPromo)
      showRecordWas('created')
      if (close) {
        router.push({ name: ROUTE.CMS.NEWSLETTER_PROMO.LIST })
        return
      }
      router.push({ name: ROUTE.CMS.NEWSLETTER_PROMO.DETAIL, params: { id: newsletterPromoRes.id } })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  return {
    detailLoading,
    saveButtonLoading,
    newsletterPromo,
    fetchData,
    onUpdate,
    onCreate,
    resetStore: newsletterPromoOneStore.reset,
  }
}

export const useNewsletterPromoListActions = () => {
  const listItems = ref<NewsletterPromo[]>([])
  const selectedNewsletterList = ref<NewsletterPromo[]>([])

  const fetchList = async (pagination: Pagination, filterBag: FilterBag) => {
    listLoading.value = true
    try {
      listItems.value = await fetchNewsletterPromoList(pagination, filterBag)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }
  }

  return {
    datatableHiddenColumns,
    listLoading,
    fetchList,
    listItems,
    selectedNewsletterList,
  }
}
