import {
  type DocId,
  type IntegerId,
  type IntegerIdNullable,
  isNull,
  type ValueObjectOption,
} from '@anzusystems/common-admin'
import type { Embed, EmbedAware } from '@/components/anzutap/types/Embed'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import type { EmbedLink } from '@/components/anzutap/components/embedLink'

export const isEmbedImage = (embed: EmbedAware | Embed | null): embed is EmbedImageAware => {
  if (isNull(embed)) return false
  return embed.discriminator === 'image'
}

export interface EmbedImageAware extends EmbedAware {
  discriminator: 'image'
  image: IntegerIdNullable
  link: EmbedLink
  detail?: {
    image: {
      id: IntegerId
      texts: {
        description: string
        source: string
      }
      flags: {
        showSource: boolean
      }
      dam: {
        damId: DocId
        licenceId: IntegerId
        regionPosition: number
        animation: boolean
      }
    }
  }
  layout: EmbedImageLayoutType
  align: EmbedImageAlignType
}

export interface EmbedImageCreateUpdateDto
  extends Omit<
    EmbedImageAware,
    'detail' | 'discriminator' | '_resourceName' | '_system' | 'createdAt' | 'modifiedAt' | 'createdBy' | 'modifiedBy'
  > {}

export const EmbedImageLayout = {
  Size100: 'size100',
  Size20: 'size20',
  Size120: 'size120',
  Parallax: 'parallax',
} as const
export const EmbedImageLayoutDefault = EmbedImageLayout.Size100
export type EmbedImageLayoutType = (typeof EmbedImageLayout)[keyof typeof EmbedImageLayout]

export function useEmbedImageLayout() {
  const { t } = useI18n()

  const embedImageLayoutOptions = ref<ValueObjectOption<EmbedImageLayoutType>[]>([
    {
      value: EmbedImageLayout.Size100,
      title: t('editor.node.embedImage.layout.size100'),
    },
    {
      value: EmbedImageLayout.Size20,
      title: t('editor.node.embedImage.layout.size20'),
    },
    {
      value: EmbedImageLayout.Size120,
      title: t('editor.node.embedImage.layout.size120'),
    },
    {
      value: EmbedImageLayout.Parallax,
      title: t('editor.node.embedImage.layout.parallax'),
    },
  ])

  const getEmbedImageLayoutOption = (value: EmbedImageLayoutType) => {
    return embedImageLayoutOptions.value.find((item) => item.value === value)
  }

  return {
    embedImageLayoutOptions,
    getEmbedImageLayoutOption,
  }
}

export const EmbedImageAlign = {
  None: 'none',
  Left: 'left',
  Right: 'right',
} as const
export const EmbedImageAlignDefault = EmbedImageAlign.None
export type EmbedImageAlignType = (typeof EmbedImageAlign)[keyof typeof EmbedImageAlign]

export function useEmbedImageAlign() {
  const { t } = useI18n()

  const embedImageAlignOptions = ref<ValueObjectOption<EmbedImageAlignType>[]>([
    {
      value: EmbedImageAlign.None,
      title: t('editor.node.embedImage.align.none'),
    },
    {
      value: EmbedImageAlign.Left,
      title: t('editor.node.embedImage.align.left'),
    },
    {
      value: EmbedImageAlign.Right,
      title: t('editor.node.embedImage.align.right'),
    },
  ])

  const getEmbedImageAlignOption = (value: EmbedImageAlignType) => {
    return embedImageAlignOptions.value.find((item) => item.value === value)
  }

  return {
    embedImageAlignOptions,
    getEmbedImageAlignOption,
  }
}
