import type { EnvConfig, EnvConfigStored } from '@/types/EnvConfig'

export const envConfig: EnvConfigStored = {
  adminSwitcherConfigUrl: '',
  anzutapDebug: false,
  apiLogError: {
    apiUrl: '',
    enabled: false,
  },
  appEnvironment: '',
  appVersion: '',
  collab: {
    enabled: false,
    socketUrl: '',
    hocuspocusUrl: '',
  },
  cookies: {
    refreshTokenExistsName: 'anz_rte',
    jwtPayloadName: 'anz_jp',
  },
  loginUrl: '',
  logoutUrl: '',
  mailerDomain: '',
  cms: {
    apiUrl: '',
    apiTimeout: 1,
    newsletter: {
      damSelectLicences: [],
      damUploadLicence: 0,
    },
    layout: {
      damSelectLicences: [],
      damUploadLicence: 0,
    },
  },
  scraper: {
    apiUrl: '',
    apiTimeout: 1,
  },
  weather: {
    apiUrl: '',
    webDomain: '',
    apiTimeout: 1,
  },
  contentHub: {
    enabled: false,
    apiUrl: '',
    apiTimeout: 1,
  },
  dam: {
    apiUrl: '',
    apiTimeout: 1,
    imageUrl: '',
    notification: {
      enabled: false,
      webSocketUrl: '',
    },
  },
  notification: {
    enabled: false,
    apiUrl: '',
    apiTimeout: 1,
  },
  owl: {
    enabled: false,
    apiUrl: '',
    apiTimeout: 1,
    timeAggregations: {
      articleView: '',
      articleQualityView: '',
      articleTimeSpent: '',
    },
    sumAggregations: {
      all: {
        articleView: '',
        articleQualityView: '',
        articleTimeSpent: '',
      },
      app: {
        articleView: '',
        articleQualityView: '',
        articleTimeSpent: '',
      },
    },
  },
  googleMapApiKey: '',
  googleGtmAccountId: '',
}

const setEnvConfig = (data: EnvConfig) => {
  try {
    envConfig.adminSwitcherConfigUrl = data.adminSwitcherConfigUrl
    envConfig.anzutapDebug = data.anzutapDebug
    envConfig.apiLogError.enabled = data.apiLogError.enabled
    envConfig.apiLogError.apiUrl = data.apiLogError.apiUrl

    envConfig.appEnvironment = data.appEnvironment
    envConfig.appVersion = data.appVersion

    envConfig.collab.enabled = data.collab.enabled
    envConfig.collab.socketUrl = data.collab.socketUrl
    envConfig.collab.hocuspocusUrl = data.collab.hocuspocusUrl

    envConfig.cookies.refreshTokenExistsName = data.cookies.refreshTokenExistsName
    envConfig.cookies.jwtPayloadName = data.cookies.jwtPayloadName

    envConfig.loginUrl = data.loginUrl
    envConfig.logoutUrl = data.logoutUrl

    envConfig.mailerDomain = data.mailerDomain

    envConfig.cms.apiUrl = data.cms.apiUrl
    envConfig.cms.apiTimeout = data.cms.apiTimeout
    envConfig.cms.newsletter.damSelectLicences = data.cms.newsletter.damSelectLicences.split(',').map(Number)
    envConfig.cms.newsletter.damUploadLicence = data.cms.newsletter.damUploadLicence
    envConfig.cms.layout.damSelectLicences = data.cms.layout.damSelectLicences.split(',').map(Number)
    envConfig.cms.layout.damUploadLicence = data.cms.layout.damUploadLicence

    envConfig.scraper.apiUrl = data.scraper.apiUrl
    envConfig.scraper.apiTimeout = data.scraper.apiTimeout

    envConfig.contentHub.enabled = data.contentHub.enabled
    envConfig.contentHub.apiUrl = data.contentHub.apiUrl
    envConfig.contentHub.apiTimeout = data.contentHub.apiTimeout

    envConfig.weather.apiUrl = data.weather.apiUrl
    envConfig.weather.webDomain = data.weather.webDomain
    envConfig.weather.apiTimeout = data.weather.apiTimeout

    envConfig.dam.apiUrl = data.dam.apiUrl
    envConfig.dam.apiTimeout = data.dam.apiTimeout
    envConfig.dam.imageUrl = data.dam.imageUrl
    envConfig.dam.notification.enabled = data.dam.notification.enabled
    envConfig.dam.notification.webSocketUrl = data.dam.notification.webSocketUrl

    envConfig.notification.enabled = data.notification.enabled
    envConfig.notification.apiUrl = data.notification.apiUrl
    envConfig.notification.apiTimeout = data.notification.apiTimeout

    envConfig.owl.enabled = data.owl.enabled
    envConfig.owl.apiUrl = data.owl.apiUrl
    envConfig.owl.apiTimeout = data.owl.apiTimeout
    envConfig.owl.timeAggregations.articleView = data.owl.timeAggregations.articleView
    envConfig.owl.timeAggregations.articleQualityView = data.owl.timeAggregations.articleQualityView
    envConfig.owl.timeAggregations.articleTimeSpent = data.owl.timeAggregations.articleTimeSpent
    envConfig.owl.sumAggregations.all.articleView = data.owl.sumAggregations.all.articleView
    envConfig.owl.sumAggregations.all.articleQualityView = data.owl.sumAggregations.all.articleQualityView
    envConfig.owl.sumAggregations.all.articleTimeSpent = data.owl.sumAggregations.all.articleTimeSpent
    envConfig.owl.sumAggregations.app.articleView = data.owl.sumAggregations.app.articleView
    envConfig.owl.sumAggregations.app.articleQualityView = data.owl.sumAggregations.app.articleQualityView
    envConfig.owl.sumAggregations.app.articleTimeSpent = data.owl.sumAggregations.app.articleTimeSpent

    envConfig.googleMapApiKey = data.googleMapApiKey
    envConfig.googleGtmAccountId = data.googleGtmAccountId
  } catch (err) {
    throw new Error('Unable to load env config. Incorrect fields in json.')
  }
}

const getNavigatorLanguage = (): string => {
  return navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : // @ts-ignore
      navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en'
}

const onConfigError = (error: Error) => {
  const userLang = getNavigatorLanguage()
  let errorMessage = 'Fatal error. Please contact administrator.'
  if (userLang === 'sk-SK' || userLang === 'sk') {
    errorMessage = 'Fatálna chyba. Prosím kontaktujte administrátora.'
  }
  const appDiv = document.getElementById('app')
  appDiv?.insertAdjacentHTML(
    'beforeend',
    `<div style='color:red;text-align:center;font-weight:bold;margin:20px;'>${errorMessage}</div>`
  )
  console.error(error)
}

export const loadEnvConfig = (callback: () => void) => {
  fetch('/config.json?random=' + Date.now())
    .then((res) => {
      if (res.ok) {
        const contentType = res.headers.get('content-type')
        if (!contentType || !contentType.includes('application/json')) {
          throw new Error('Unable to load env config. Incorrect content type.')
        }
        return res.json()
      }
      throw new Error('Unable to load env config. Incorrect response code.')
    })
    .then((config) => {
      if (Object.keys(config).length < 1) {
        throw new Error('Unable to load env config. Incorrect response body.')
      }
      setEnvConfig(config)
      callback()
    })
    .catch((err) => {
      onConfigError(err)
    })
}
