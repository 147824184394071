import { reactive } from 'vue'
import { dateTimeNow, makeFilterHelper } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/newsletterPromoApi'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

const filter = reactive({
  id: {
    ...makeFilter({ name: 'id', variant: 'in' }),
  },
  title: {
    ...makeFilter({ name: 'title', variant: 'contains', field: 'texts.title' }),
  },
})

export function useNewsletterPromoListFilter() {
  return filter
}

export function useActiveNewsletterPromoFilter() {
  return reactive({
    id: {
      ...makeFilter({ name: 'id', variant: 'in' }),
    },
    startAt: {
      ...makeFilter({
        name: 'startAt',
        field: 'dates.startAt',
        variant: 'lte',
        default: dateTimeNow(),
      }),
    },
    endAt: {
      ...makeFilter({
        name: 'endAt',
        field: 'dates.endAt',
        variant: 'gte',
        default: dateTimeNow(),
      }),
    },
    enabled: {
      ...makeFilter({ name: 'enabled', default: true }),
    },
    type: {
      ...makeFilter({ name: 'type' }),
    },
  })
}
