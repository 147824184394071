<script lang="ts" setup>
import { ROUTE } from '@/router/routes'
import { type PersonalDashboardDtoInterface } from '@/types/cms/Dashboard'
import { useI18n } from 'vue-i18n'

withDefaults(
  defineProps<{
    dashboard: PersonalDashboardDtoInterface
  }>(),
  {}
)
const { t } = useI18n()
</script>

<template>
  <ABtnSecondary
    class="my-2 mr-2"
    size="x-large"
    :to="{ name: ROUTE.CMS.USER.DASHBOARD }"
  >
    {{ t('cms.dashboard.dashboards.personal') }}
  </ABtnSecondary>
</template>
