import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export function useNewsletterScheduleType() {
  const { t } = useI18n()

  const dayOfWeekOptions = ref<ValueObjectOption<number>[]>([
    {
      value: 1,
      title: t('cms.newsletterSchedule.weekDays.monday'),
    },
    {
      value: 2,
      title: t('cms.newsletterSchedule.weekDays.tuesday'),
    },
    {
      value: 3,
      title: t('cms.newsletterSchedule.weekDays.wednesday'),
    },
    {
      value: 4,
      title: t('cms.newsletterSchedule.weekDays.thursday'),
    },
    {
      value: 5,
      title: t('cms.newsletterSchedule.weekDays.friday'),
    },
    {
      value: 6,
      title: t('cms.newsletterSchedule.weekDays.saturday'),
    },
    {
      value: 7,
      title: t('cms.newsletterSchedule.weekDays.sunday'),
    },
  ])

  const dayOfMonthArray = []
  for (let i = 1; i <= 31; i++) {
    dayOfMonthArray.push({
      value: i,
      title: i.toString(),
    })
  }
  const dayOfMonthOptions = ref<ValueObjectOption<number>[]>(dayOfMonthArray)

  const hourArray = []
  for (let i = 0; i <= 23; i++) {
    hourArray.push({
      value: i,
      title: i.toString(),
    })
  }
  const hourOptions = ref<ValueObjectOption<number>[]>(hourArray)

  const minuteOptions = ref<ValueObjectOption<number>[]>([
    {
      value: 0,
      title: '0',
    },
    {
      value: 15,
      title: '15',
    },
    {
      value: 30,
      title: '30',
    },
    {
      value: 45,
      title: '45',
    },
  ])

  const getDayOfWeekOptions = (value: number) => {
    return dayOfWeekOptions.value.find((item) => item.value === value)
  }

  const getDayOfMonthOptions = (value: number) => {
    return dayOfMonthOptions.value.find((item) => item.value === value)
  }

  const getHourOptions = (value: number) => {
    return hourOptions.value.find((item) => item.value === value)
  }

  const getMinuteOptions = (value: number) => {
    return minuteOptions.value.find((item) => item.value === value)
  }

  return {
    dayOfWeekOptions,
    getDayOfWeekOptions,
    dayOfMonthOptions,
    getDayOfMonthOptions,
    hourOptions,
    getHourOptions,
    minuteOptions,
    getMinuteOptions,
  }
}
