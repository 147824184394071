<script setup lang="ts">
import type { Newsletter, NewsletterGeneratorSettings } from '@/types/cms/Newsletter'
import NewsletterGeneratorSettingsDialog from '@/views/cms/newsletter/components/NewsletterGeneratorSettingsDialog.vue'
import NewsletterGeneratorSettingsItem from '@/views/cms/newsletter/components/NewsletterGeneratorSettingsItem.vue'
import NewsletterGeneratorSettingsRow from '@/views/cms/newsletter/components/NewsletterGeneratorSettingsRow.vue'
import { useGeneratorSettingsListActions } from '@/views/cms/newsletter/composables/newsletterGeneratorSettingsActions'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

withDefaults(
  defineProps<{
    newsletter: Newsletter
    isEdit?: boolean
  }>(),
  {
    isEdit: true,
  }
)

const deleteDialog = ref(false)
const generatorSettingsToRemove = ref<number | null>(null)

const { openDialog, deleteGeneratorSettingsByTemplateId, generatorSettingsLoading } = useGeneratorSettingsListActions()

const { t } = useI18n()
const onEditClick = (generatorSettings: NewsletterGeneratorSettings) => {
  openDialog(generatorSettings)
}

const onDeleteConfirm = async (dataSourceUrl: string) => {
  await deleteGeneratorSettingsByTemplateId(dataSourceUrl)
  generatorSettingsToRemove.value = null
  deleteDialog.value = false
}
</script>

<template>
  <NewsletterGeneratorSettingsDialog :newsletter="newsletter" />
  <div>
    <h4 class="font-weight-bold text-subtitle-2">
      generators
    </h4>
    <VBtn
      v-if="isEdit"
      size="small"
      prepend-icon="mdi-plus"
      @click.stop="openDialog(undefined)"
    >
      {{ t('cms.newsletter.model.generatorSettings.addNew') }}
    </VBtn>
    <div class="anzu-comments mt-2">
      <div
        v-if="generatorSettingsLoading"
        class="w-100 d-flex align-center justify-center"
      >
        <VProgressCircular indeterminate />
      </div>
      <div v-else>
        <div v-if="isEdit">
          <table>
            <thead>
              <tr>
                <th>templateId</th>
                <th>dataSourceUrl</th>
              </tr>
            </thead>
            <tbody>
              <NewsletterGeneratorSettingsItem
                v-for="item in newsletter.settings.generators"
                :key="item.templateId"
                :item="item"
                @on-delete-click="onDeleteConfirm(item.dataSourceUrl)"
                @on-edit-click="onEditClick"
              />
            </tbody>
          </table>
        </div>
        <div v-else>
          <table>
            <thead>
              <tr>
                <th>templateId</th>
                <th>dataSourceUrl</th>
              </tr>
            </thead>
            <tbody>
              <NewsletterGeneratorSettingsRow
                v-for="item in newsletter.settings.generators"
                :key="item.templateId"
                :settings="item"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
