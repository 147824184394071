import { useI18n } from 'vue-i18n'

export function useDateTimeHelper() {
  const { t } = useI18n()
  const weekDayTranslate: string[] = []
  weekDayTranslate[1] = t('cms.newsletterSchedule.weekDays.monday')
  weekDayTranslate[2] = t('cms.newsletterSchedule.weekDays.tuesday')
  weekDayTranslate[3] = t('cms.newsletterSchedule.weekDays.wednesday')
  weekDayTranslate[4] = t('cms.newsletterSchedule.weekDays.thursday')
  weekDayTranslate[5] = t('cms.newsletterSchedule.weekDays.friday')
  weekDayTranslate[6] = t('cms.newsletterSchedule.weekDays.saturday')
  weekDayTranslate[7] = t('cms.newsletterSchedule.weekDays.sunday')

  const getDayTranslateByNumber = (number: number) => {
    return weekDayTranslate[number]
  }

  return {
    getDayTranslateByNumber,
  }
}
