import useVuelidate from '@vuelidate/core'
import type { Ref } from 'vue'
import { useValidate } from '@anzusystems/common-admin'
import type { NewsletterPromo } from '@/types/cms/NewsletterPromo'

export const NewsletterPromoValidationSymbol = Symbol.for('anzu:cms:newsletter-promo-validation-scope')

export function useNewsletterPromoValidation(newsletterPromo: Ref<NewsletterPromo>) {
  const { maxLength, minLength, required } = useValidate()

  const rules = {
    newsletterPromo: {
      texts: {
        title: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        titlePublic: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        body: {
          required,
        },
        linkedText: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
      },
      dates: {
        startAt: {
          required,
        },
      },
      url: {
        required,
        maxLength: maxLength(512),
      },
    },
  }
  const v$ = useVuelidate(rules, { newsletterPromo }, { $scope: NewsletterPromoValidationSymbol })

  return {
    v$,
  }
}
