<script lang="ts" setup>
import { ArticleStageStatus, type ArticleStageStatusType } from '@/model/cms/valueObject/ArticleStageStatus'
import { type IntegerId, isUndefined } from '@anzusystems/common-admin'
import { ArticleStagePriority, type ArticleStagePriorityType } from '@/model/cms/valueObject/ArticleStagePriority'
import { computed } from 'vue'
import { useStages } from '@/views/cms/stage/composables/stagesAll'

const props = withDefaults(
  defineProps<{
    stageId: IntegerId
    priority?: ArticleStagePriorityType | undefined
    status?: ArticleStageStatusType | undefined
  }>(),
  {
    priority: undefined,
    status: undefined,
  }
)

const { getStage } = useStages()

const titleComputed = computed(() => {
  const stage = getStage(props.stageId)
  if (isUndefined(stage)) {
    return undefined
  }

  if (isUndefined(props.status)) {
    return stage.name
  }

  return props.status === ArticleStageStatus.New ? stage.activeName : stage.solvedName
})

const highPriorityComputed = computed(() => {
  return !isUndefined(props.priority) && props.priority === ArticleStagePriority.High
})

const colorComputed = computed(() => {
  if (isUndefined(props.status)) {
    return 'default'
  }

  if (highPriorityComputed.value && props.status === ArticleStageStatus.New) {
    return 'error'
  }

  if (props.status === ArticleStageStatus.New) {
    return 'warning'
  }

  return 'success'
})
</script>

<template>
  <VChip
    v-if="titleComputed"
    label
    size="small"
    :color="colorComputed"
  >
    <VIcon
      v-if="highPriorityComputed"
      class="mr-1"
      icon="mdi-alert"
    />
    {{ titleComputed }}
  </VChip>
</template>
