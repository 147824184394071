import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import { apiAnyRequest, type ApiInfiniteResponseList, isUndefined } from '@anzusystems/common-admin'
import type { DashboardDtoInterface } from '@/types/cms/Dashboard'
import type { DashboardTypeType } from '@/model/cms/valueObject/DashboardType'

const END_POINT = '/adm/dashboards'
export const fetchDashboardList = (
  type?: DashboardTypeType | undefined
): Promise<ApiInfiniteResponseList<DashboardDtoInterface[]>> => {
  let url = END_POINT
  if (!isUndefined(type)) {
    url += '?type=' + type
  }

  return apiAnyRequest<ApiInfiniteResponseList<DashboardDtoInterface[]>>(
    cmsClient,
    'GET',
    url,
    {},
    null,
    SYSTEM_CMS,
    ''
  )
}
