import type { TargetPosition, WebEntityTypeType } from '@/types/cms/TargetPosition'
import type {
  AnzuUserAndTimeTrackingAware,
  IntegerId,
  IntegerIdNullable,
  SortableItemDataAware,
} from '@anzusystems/common-admin'
import type { JSONContent } from '@tiptap/core'

export interface LayoutTemplate extends AnzuUserAndTimeTrackingAware {
  id: IntegerId
  texts: LayoutTemplateTexts
  attributes: LayoutTemplateAttributes
  image: IntegerIdNullable
  layoutTemplateTargetPositions: LayoutTemplateTargetPosition[]
  _resourceName: 'layoutTemplate'
  _system: 'cms'
}

interface LayoutTemplateTexts {
  title: string
  description: string
  helpBody: JSONContent
}

interface LayoutTemplateAttributes {
  webEntityType: WebEntityTypeType
  articleWebAdvertType: ArticleWebAdvertTypeType
  default: boolean
  wideForm: boolean
}

export interface LayoutTemplateTargetPosition extends SortableItemDataAware {
  id: IntegerId
  enabled: boolean
  position: number
  layoutTemplate: IntegerId
  targetPosition: IntegerId
  readonly targetPositionDetail: TargetPosition
  _resourceName: 'layoutTemplateTargetPosition'
  _system: 'cms'
}

export interface LayoutTemplateMinimal {
  id: IntegerId
  title: string
}

export const ArticleWebAdvertType = {
  None: 'none',
  Standard: 'standard',
  WideForm: 'wideForm',
  MobileExport: 'mobileExport',
} as const
export const ArticleWebAdvertTypeDefault = ArticleWebAdvertType.None
export type ArticleWebAdvertTypeType = (typeof ArticleWebAdvertType)[keyof typeof ArticleWebAdvertType]
