<script lang="ts" setup>
import { type IntegerId, useCollabHelpers } from '@anzusystems/common-admin'
import { useArticleStageListActions } from '@/views/cms/articleStage/composables/articleStageActions'
import { computed } from 'vue'
import { useArticleStageListStore } from '@/stores/cms/articleStageStore'
import CollabRoomInfo from '@/components/collab/CollabRoomInfo.vue'

const props = withDefaults(
  defineProps<{
    id: null | IntegerId | undefined
  }>(),
  {}
)

const articleStageListStore = useArticleStageListStore()
const { articleRelLoading } = useArticleStageListActions()

const article = computed(() => {
  return articleStageListStore.getArticleByArticleStage(props.id ?? 0)
})

const { createCollabRoom } = useCollabHelpers()
</script>

<template>
  <VProgressCircular
    v-if="articleRelLoading"
    :size="12"
    :width="2"
    indeterminate
    class="mx-1"
  />
  <div v-else-if="article">
    <div class="d-flex flex-wrap align-center gc-2 py-1">
      <span class="td--title line-clamp-2">{{ article.texts.headline }}</span>
      <CollabRoomInfo :collab-room="createCollabRoom(article.id, 'article', 'cms')" />
    </div>
  </div>
</template>
