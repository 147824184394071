<script lang="ts" setup>
import { usePageFilter } from '@/model/cms/filter/PageFilter'
import { usePageSelectActions } from '@/views/cms/page/composables/pageActions'
import { AFormRemoteAutocomplete, type IntegerId } from '@anzusystems/common-admin'
import { isDefined } from '@vueuse/core'

const props = withDefaults(
  defineProps<{
    label?: string | undefined
    required?: boolean | undefined
    multiple?: boolean
    template?: boolean
    mainContentDiscriminator?: string | undefined
    siteGroupId?: IntegerId | undefined | null
  }>(),
  {
    label: undefined,
    required: undefined,
    multiple: false,
    template: false,
    mainContentDiscriminator: undefined,
    siteGroupId: undefined,
  }
)
const modelValue = defineModel<string | IntegerId | string[] | IntegerId[] | null>({ required: true })

const { fetchItems, fetchItemsByIds } = usePageSelectActions()

const innerFilter = usePageFilter()
if (props.template) innerFilter.template.model = true
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
if (isDefined(props.mainContentDiscriminator)) {
  innerFilter.mainContentDiscriminator.model = props.mainContentDiscriminator
}

if (props.siteGroupId) {
  innerFilter.siteGroup.model = props.siteGroupId
}
</script>

<template>
  <AFormRemoteAutocomplete
    v-model="modelValue"
    :required="required"
    :label="label"
    :fetch-items="fetchItems"
    :fetch-items-by-ids="fetchItemsByIds"
    :inner-filter="innerFilter"
    :multiple="multiple"
    :filter-sort-by="null"
    filter-by-field="text"
  />
</template>
