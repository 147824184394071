import type { ArticleStatDto } from '@/types/cms/ArticleStat/ArticleStatRequestDto'
import type { DocId } from '@anzusystems/common-admin'

export function useArticleStatDtoFactory() {
  const createArticleStatDto = (docId: DocId): ArticleStatDto => {
    return {
      docId: docId,
    }
  }

  return {
    createArticleStatDto,
  }
}
