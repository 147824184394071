import { SYSTEM_OWL } from '@/model/systems'
import { owlClient } from '@/services/api/clients/owlClient'
import type {
  EventOrderByType,
  MetricGroupByType,
  MetricOrderByType,
  MetricOrderDirectionType,
  MetricPoint,
} from '@/types/owl/Metric'
import { MetricGroupBy } from '@/types/owl/Metric'
import type { ObjectEvent } from '@/types/owl/ObjectEvents'
import { apiAnyRequest, isDefined, useApiQueryBuilder } from '@anzusystems/common-admin'

export const METRICS_LIMIT = 7000
export const LIST_LIMIT = 50

export const fetchMetrics = (
  aggregationId: string,
  name: string,
  orderBy: MetricOrderByType | undefined = undefined,
  orderDirection: MetricOrderDirectionType | undefined = undefined,
  groupBy: MetricGroupByType = MetricGroupBy.Minute,
  since: Date | undefined = undefined,
  limit = METRICS_LIMIT
) => {
  const { queryBuild, queryAdd } = useApiQueryBuilder()
  queryAdd('per_page', limit)
  queryAdd('name', name)
  groupBy !== MetricGroupBy.Minute && queryAdd('group_by', groupBy)
  isDefined(orderBy) && queryAdd('order_by', orderBy)
  isDefined(since) && queryAdd('since', encodeURIComponent(since.toISOString()))
  isDefined(orderDirection) && queryAdd('order', orderDirection)

  return apiAnyRequest<MetricPoint[]>(
    owlClient,
    'GET',
    '/metrics/:aggregationId' + queryBuild(),
    { aggregationId },
    undefined,
    SYSTEM_OWL,
    'owl'
  )
}

export const fetchObjectEvents = (
  objectId: string,
  orderBy: EventOrderByType | undefined = undefined,
  orderDirection: MetricOrderDirectionType | undefined = undefined,
  limit = LIST_LIMIT
) => {
  const { queryBuild, queryAdd } = useApiQueryBuilder()
  queryAdd('per_page', limit)
  isDefined(orderBy) && queryAdd('order_by', orderBy)
  isDefined(orderDirection) && queryAdd('order', orderDirection)

  return apiAnyRequest<ObjectEvent[]>(
    owlClient,
    'GET',
    '/objects/:objectId/events' + queryBuild(),
    { objectId },
    undefined,
    SYSTEM_OWL,
    'owl'
  )
}
