import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import { apiAnyRequest } from '@anzusystems/common-admin'
import type { ArticleStatDto, ArticleStatRequestDto } from '@/types/cms/ArticleStat/ArticleStatRequestDto'
import type { ArticleStat } from '@/types/cms/ArticleStat/ArticleStat'

const END_POINT = '/adm/v1/article-stats'
export const ENTITY = 'articleStat'

export const getArticleStats = (data: Array<ArticleStatDto>) =>
  apiAnyRequest<ArticleStatRequestDto, Array<ArticleStat>>(
    cmsClient,
    'POST',
    END_POINT,
    undefined,
    {
      stats: data,
    },
    SYSTEM_CMS,
    ENTITY
  )
