<script setup lang="ts">
import EmbedRelatedDialogItemKindArticleForm from '@/components/anzutap/nodes/embedRelated/components/EmbedRelatedDialogItemKindArticleForm.vue'
import EmbedRelatedDialogItemKindLinkForm from '@/components/anzutap/nodes/embedRelated/components/EmbedRelatedDialogItemKindLinkForm.vue'
import { useBookmarkFactory } from '@/model/cms/factory/BookmarkFactory'
import { useRelatedItemFactory } from '@/model/cms/factory/RelatedItemFactory'
import { createBookmark, deleteBookmark, fetchArticleBookmark, updateBookmark } from '@/services/api/cms/bookmarkApi'
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import type { ArticleKind } from '@/types/cms/ArticleKind/ArticleKind'
import type { Bookmark } from '@/types/cms/Bookmark'
import {
  isRelatedItemDetailKindArticle,
  isRelatedItemDetailKindLink,
  isRelatedItemKindArticle,
  isRelatedItemKindLink,
  type RelatedItemDetailKind,
  RelatedItemDiscriminator,
  type RelatedItemKind,
} from '@/types/cms/RelatedItem'
import type { CollabRoom } from '@/types/Collab'
import ArticleSelectTable from '@/views/cms/article/components/ArticleSelectTable.vue'
import {
  ADialogToolbar,
  ASortable,
  type CollabFieldData,
  type CollabStatusType,
  type DocId,
  generateUUIDv4,
  HTTP_STATUS_NOT_FOUND,
  HTTP_STATUS_UNPROCESSABLE_ENTITY,
  isAnzuFatalError,
  isArray,
  isDefined,
  isNull,
  isUndefined,
  type SortableItem,
  useAlerts,
  useCollabField,
  useCommonAdminCollabOptions,
} from '@anzusystems/common-admin'
import axios from 'axios'
import { storeToRefs } from 'pinia'
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const bookmark = ref<Bookmark | null>(null)
const status = ref<'loading' | 'loaded' | 'error'>('loading')
const dialog = ref(false)
const sortableComponent = ref<InstanceType<typeof ASortable> | null>(null)
const saveButtonLoading = ref(false)
const articlesAlreadyBookmarked = ref<DocId[]>([])
const maxCount = 50

// Collaboration
const { collabOptions } = useCommonAdminCollabOptions()
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const changeFieldData = ref((data: CollabFieldData) => {})
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
if (collabOptions.value.enabled && isDefined(props.collabRoom)) {
  const { changeCollabFieldData } = useCollabField(props.collabRoom, 'articleBookmarks')
  changeFieldData.value = changeCollabFieldData
}

const { t } = useI18n()

const articleKindOneStore = useArticleKindOneStore()
const { article, articleBookmarksReload } = storeToRefs(articleKindOneStore)

const { showErrorsDefault } = useAlerts()

const init = async () => {
  try {
    bookmark.value = await fetchArticleBookmark(article.value.docId)
    status.value = 'loaded'
  } catch (error) {
    if (
      isAnzuFatalError(error) &&
      axios.isAxiosError(error.cause) &&
      error.cause.response?.status === HTTP_STATUS_NOT_FOUND
    ) {
      status.value = 'loaded'
      return
    }
    showErrorsDefault(error)
    status.value = 'error'
  }
}

const { createDefault } = useBookmarkFactory()
const { createItemLink, createItemArticle } = useRelatedItemFactory()

const onCreate = async () => {
  const bookmarkData = createDefault()
  bookmarkData.siteGroup = article.value.siteGroup
  const item = createItemArticle()
  item.articleDocId = article.value.docId
  bookmarkData.items.push(item)
  bookmarkData.detailItems = []
  bookmarkData.detailItems.push({
    id: generateUUIDv4(),
    position: -1,
    overline: '',
    headline: '',
    perex: '',
    article: {
      id: article.value.id,
      docId: article.value.docId,
      url: '#',
      status: article.value.status,
      texts: {
        overline: article.value.texts.overline,
        headline: article.value.texts.headline,
        perex: article.value.texts.perex,
      },
      dates: article.value.dates,
      image: article.value.listingImage ?? article.value.leadImage,
    },
    discriminator: RelatedItemDiscriminator.Article,
  })
  bookmark.value = bookmarkData
  dialog.value = true
}

const onEdit = async () => {
  if (isNull(bookmark.value)) return
  onRefresh()
  dialog.value = true
}

const onDeleteItem = async (item: SortableItem) => {
  if (isNull(bookmark.value)) return
  if (bookmark.value.items.length === 1) {
    try {
      await deleteBookmark(bookmark.value.id)
      bookmark.value = null
      dialog.value = false
    } catch (error) {
      showErrorsDefault(error)
    }
    return
  }
  sortableComponent.value?.removeByIndex(item.index)
}

const onConfirm = async () => {
  if (isNull(bookmark.value)) return
  saveButtonLoading.value = true
  articlesAlreadyBookmarked.value = []
  try {
    if (bookmark.value.id === 0) {
      bookmark.value = await createBookmark(bookmark.value)
    } else {
      bookmark.value = await updateBookmark(bookmark.value.id, bookmark.value)
    }
    dialog.value = false
  } catch (error) {
    if (
      isAnzuFatalError(error) &&
      axios.isAxiosError(error.cause) &&
      error.cause.response?.status === HTTP_STATUS_UNPROCESSABLE_ENTITY &&
      error.cause.response?.data?.error === 'article_already_bookmarked'
    ) {
      articlesAlreadyBookmarked.value = isArray(error.cause.response?.data?.articlesDocIds)
        ? error.cause.response.data.articlesDocIds
        : []
    } else {
      showErrorsDefault(error)
    }
  } finally {
    saveButtonLoading.value = false
    if (collabOptions.value.enabled && props.isModerator) {
      changeFieldData.value(articleBookmarksReload.value + 1)
    }
  }
}

const onClose = () => {
  if (!isNull(bookmark.value) && bookmark.value.id === 0) {
    bookmark.value = null
    articlesAlreadyBookmarked.value = []
  }
  dialog.value = false
}

const onBrowseConfirm = async (data: Array<ArticleKind>) => {
  if (data.length === 0) return
  if (isNull(bookmark.value)) return
  if (bookmark.value.items.length > maxCount) return
  const detailArticles: Array<RelatedItemDetailKind> = []
  const items = data.map((item) => {
    const relatedItem = createItemArticle()
    relatedItem.articleDocId = item.docId
    relatedItem.overline = ''
    relatedItem.headline = ''
    relatedItem.perex = ''
    detailArticles.push({
      id: generateUUIDv4(),
      position: -1,
      overline: item.texts.overline,
      headline: item.texts.headline,
      perex: item.texts.perex,
      article: {
        id: item.id,
        docId: item.docId,
        url: '#',
        status: item.status,
        texts: {
          overline: item.texts.overline,
          headline: item.texts.headline,
          perex: item.texts.perex,
        },
        dates: item.dates,
        image: item.listingImage ?? item.leadImage,
      },
      discriminator: RelatedItemDiscriminator.Article,
    })
    return relatedItem
  })
  bookmark.value.items = [...bookmark.value.items, ...items]
  if (isUndefined(bookmark.value.detailItems)) {
    bookmark.value.detailItems = []
  }
  bookmark.value.detailItems = [...bookmark.value.detailItems, ...detailArticles]
  updatePositions()
}

const updatePositions = () => {
  if (isNull(bookmark.value)) return
  bookmark.value.items.map((item, index) => {
    item.position = index
  })
}

const addExternal = () => {
  if (isNull(bookmark.value)) return
  if (bookmark.value.items.length > maxCount) return
  bookmark.value.items.push(createItemLink())
  updatePositions()
}

const onRefresh = async () => {
  status.value = 'loading'
  await init()
}

watch(articleBookmarksReload, () => {
  onRefresh()
})

onMounted(() => {
  init()
})
</script>

<template>
  <div
    v-if="status === 'loading'"
    class="w-100 d-flex align-center justify-center"
  >
    <VProgressCircular indeterminate />
  </div>
  <div
    v-else-if="status === 'error'"
    class="w-100 d-flex align-center justify-space-between"
  >
    Error
  </div>
  <template v-else-if="article.id && article.siteGroup">
    <div
      v-if="isNull(bookmark) || bookmark.items.length === 0"
      class="d-flex align-center justify-space-between"
    >
      <div>{{ t('cms.bookmark.noBookmarks') }}</div>
      <div v-if="isNull(bookmark) && isModerator && !readonly">
        <ABtnTertiary
          size="small"
          @click.stop="onCreate"
        >
          {{ t('cms.bookmark.create') }}
        </ABtnTertiary>
      </div>
    </div>
    <div
      v-else-if="bookmark.detailItems"
      class="d-flex align-center justify-space-between"
    >
      <div class="d-flex flex-column">
        <template
          v-for="(item, index) in bookmark.detailItems"
          :key="item.id"
        >
          <div
            class="d-flex my-1"
            :class="{
              'font-weight-bold': isRelatedItemDetailKindArticle(item) && item.article.docId === article.docId,
            }"
          >
            <div class="mr-2">
              {{ index + 1 }}.
            </div>
            <div v-if="isRelatedItemDetailKindArticle(item)">
              {{ item.headline || item.article.texts.headline }}<br>
              <span
                v-if="item.headline.length > 0"
                class="text-caption text-medium-emphasis"
              >{{ item.article.texts.headline }}</span>
            </div>
            <div v-else-if="isRelatedItemDetailKindLink(item)">
              {{ item.headline }}<br>
              <a
                class="text-caption text-medium-emphasis"
                target="_blank"
                rel="noopener noreferrer"
              >{{ item.url }}</a>
            </div>
          </div>
        </template>
      </div>
      <div v-if="!isNull(bookmark) && isModerator && !readonly">
        <ABtnTertiary
          size="small"
          @click.stop="onEdit"
        >
          {{ t('cms.bookmark.edit') }}
        </ABtnTertiary>
      </div>
    </div>
  </template>
  <VDialog
    v-if="dialog"
    v-model="dialog"
    :width="700"
  >
    <VCard v-if="dialog && bookmark">
      <ADialogToolbar @on-cancel="onClose">
        {{ t('cms.bookmark.editBookmarks') }}
      </ADialogToolbar>
      <VCardText>
        <div
          v-if="status === 'loading'"
          class="w-100 d-flex align-center justify-center"
        >
          <VProgressCircular indeterminate />
        </div>
        <template v-else>
          <ASortable
            ref="sortableComponent"
            v-model="bookmark.items"
            show-delete-button
            @on-delete="onDeleteItem"
          >
            <template #item="{ item }: { item: SortableItem<RelatedItemKind> }">
              <EmbedRelatedDialogItemKindArticleForm
                v-if="isRelatedItemKindArticle(item.raw) && bookmark.detailItems"
                v-model="item.raw"
                :detail-items="bookmark.detailItems"
                hide-image
                show-detail-link
                :error="
                  articlesAlreadyBookmarked.includes(item.raw.articleDocId)
                    ? t('cms.bookmark.alreadyBookmarked')
                    : undefined
                "
              />
              <EmbedRelatedDialogItemKindLinkForm
                v-else-if="isRelatedItemKindLink(item.raw)"
                v-model="item.raw"
              />
            </template>
          </ASortable>
          <div v-show="bookmark.items.length < maxCount">
            <ArticleSelectTable
              ref="articleSelectTableComponent"
              :max-count="1"
              :min-count="1"
              :site-group="article.siteGroup"
              return-type="article"
              :keywords="article.keywords"
              @on-confirm="onBrowseConfirm"
            >
              <template #activator="{ props: activatorProps }">
                <div class="mb-2">
                  <div class="anzutap-dialog__button-group">
                    <VBtn
                      color="primary"
                      variant="text"
                      class="my-2 mr-2"
                      size="small"
                      @click.stop="addExternal"
                    >
                      {{ t('cms.bookmark.addExternal') }}
                    </VBtn>
                    <VBtn
                      color="primary"
                      variant="text"
                      class="my-2 mr-2"
                      size="small"
                      v-bind="activatorProps"
                    >
                      {{ t('cms.bookmark.addArticle') }}
                    </VBtn>
                  </div>
                </div>
              </template>
            </ArticleSelectTable>
          </div>
        </template>
      </VCardText>
      <VCardActions>
        <VSpacer />
        <ABtnTertiary @click.stop="onClose">
          {{ t('common.button.cancel') }}
        </ABtnTertiary>
        <ABtnPrimary
          v-if="status === 'loaded'"
          :loading="saveButtonLoading"
          @click.stop="onConfirm"
        >
          {{ t('common.button.confirm') }}
        </ABtnPrimary>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
