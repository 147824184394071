import type { ArticleSumMetrics } from '@/types/owl/ArticleSumMetrics'

export function useArticleSumMetricsFactory() {
  const createDefault = (): ArticleSumMetrics => {
    return {
      all: {
        viewCount: 0,
        qualityViewCount: 0,
        timeSpentCount: 0,
      },
      app: {
        viewCount: 0,
        qualityViewCount: 0,
        timeSpentCount: 0,
      },
    }
  }

  return {
    createDefault,
  }
}
