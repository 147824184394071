import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/pageApi'
import { makeFilterHelper } from '@anzusystems/common-admin'
import { reactive } from 'vue'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

const filter = reactive({
  _elastic: {
    ...makeFilter({ exclude: true }),
  },
  id: {
    ...makeFilter({ name: 'id' }),
  },
  text: {
    ...makeFilter({ name: 'text' }),
  },
  parent: {
    ...makeFilter({ name: 'parent', field: 'parentId' }),
  },
  contentItemDiscriminator: {
    ...makeFilter({ name: 'contentItemDiscriminator' }),
  },
  site: {
    ...makeFilter({ name: 'site', field: 'siteId' }),
  },
  siteGroup: {
    ...makeFilter({ name: 'siteGroup', field: 'siteGroupId' }),
  },
  keywords: {
    ...makeFilter({ name: 'keywords', field: 'keywordIds', default: [] }),
  },
  linkedList: {
    ...makeFilter({ name: 'linkedList', field: 'linkedListId' }),
  },
  desk: {
    ...makeFilter({ name: 'desks', field: 'deskIds', default: [] }),
  },
  designSettings: {
    ...makeFilter({ name: 'designSettings', field: 'designSettingsId' }),
  },
  advertSettings: {
    ...makeFilter({ name: 'advertSettings', field: 'advertSettingsId' }),
  },
  mainContentDiscriminator: {
    ...makeFilter({ name: 'mainContentDiscriminator' }),
  },
  enabled: {
    ...makeFilter({ name: 'enabled' }),
  },
  monitoringEnabled: {
    ...makeFilter({ name: 'monitoringEnabled' }),
  },
  template: {
    ...makeFilter({ name: 'template' }),
  },
  lastContentAddedFrom: {
    ...makeFilter({ name: 'lastContentAddedFrom' }),
  },
  lastContentAddedUntil: {
    ...makeFilter({ name: 'lastContentAddedUntil' }),
  },
})

export function usePageListFilter() {
  return filter
}

export function usePageFilter() {
  return reactive({
    _elastic: {
      ...makeFilter({ exclude: true }),
    },
    id: {
      ...makeFilter({ name: 'id' }),
    },
    text: {
      ...makeFilter({ name: 'text' }),
    },
    keywords: {
      ...makeFilter({ name: 'keywords', field: 'keywordIds', default: [] }),
    },
    linkedList: {
      ...makeFilter({ name: 'linkedList', field: 'linkedListId' }),
    },
    template: {
      ...makeFilter({ name: 'template' }),
    },
    siteGroup: {
      ...makeFilter({ name: 'siteGroup', field: 'siteGroupId' }),
    },
    mainContentDiscriminator: {
      ...makeFilter({ name: 'mainContentDiscriminator' }),
    },
  })
}
