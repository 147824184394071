<script setup lang="ts">
import { ROUTE } from '@/router/routes'
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import { useArticleRelatedEntitiesOneStore } from '@/stores/cms/articleRelatedEntitiesStore'
import { isArticleKindStandard } from '@/types/cms/ArticleKind/ArticleKindStandard'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { CollabRoom } from '@/types/Collab'
import PageRemoteAutocomplete from '@/views/cms/page/components/PageRemoteAutocomplete.vue'
import { ARow, type CollabStatusType, COMMON_CONFIG, type IntegerId } from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const articleKindOneStore = useArticleKindOneStore()
const articleRelatedEntitiesOneStore = useArticleRelatedEntitiesOneStore()
const { article } = storeToRefs(articleKindOneStore)
const { articleRelatedEntities, relatedEntitiesLoading } = storeToRefs(articleRelatedEntitiesOneStore)

const router = useRouter()

const onPageClick = (id: IntegerId) => {
  const pageRoute = router.resolve({ name: ROUTE.CMS.PAGE.DETAIL, params: { id } })
  window.open(pageRoute.href, '_blank')
}

const onPersonClick = (id: IntegerId) => {
  const pageRoute = router.resolve({ name: ROUTE.CMS.PERSON.DETAIL, params: { id } })
  window.open(pageRoute.href, '_blank')
}

const { t } = useI18n()
</script>

<template>
  <ARow>
    <PageRemoteAutocomplete
      v-if="isArticleKindStandard(article)"
      v-model="article.mainPage"
      :site-group-id="article.siteGroup"
      :disabled="readonly"
      :main-content-discriminator="ContentItemDiscriminator.ArticleList"
      :label="t('cms.articleKind.model.mainPage')"
    />
  </ARow>
  <ARow v-if="relatedEntitiesLoading">
    <VProgressLinear
      :active="relatedEntitiesLoading"
      color="primary"
      height="3"
      indeterminate
      rounded
    />
  </ARow>
  <ARow v-if="!articleRelatedEntities.person && !articleRelatedEntities.pages.length">
    {{ t('cms.articleRelatedEntities.meta.emptyRelations') }}
  </ARow>
  <ARow
    v-if="articleRelatedEntities.person"
    :title="t('cms.articleRelatedEntities.model.person')"
  >
    <VRow
      v-if="articleRelatedEntities.person"
      no-gutters
    >
      <VCol>
        <VChip
          :append-icon="COMMON_CONFIG.CHIP.ICON.LINK_EXTERNAL"
          size="small"
          class="mb-2"
          label
          @click.stop="onPersonClick(articleRelatedEntities.person.id)"
        >
          {{ articleRelatedEntities.person.texts.fullName }}
        </VChip>
      </VCol>
    </VRow>
  </ARow>
  <ARow
    v-if="articleRelatedEntities.pages.length"
    :title="t('cms.articleRelatedEntities.model.pages')"
  >
    <VRow
      v-for="relatedPage in articleRelatedEntities.pages"
      :key="relatedPage.id"
      no-gutters
    >
      <VCol>
        <VChip
          :append-icon="COMMON_CONFIG.CHIP.ICON.LINK_EXTERNAL"
          size="small"
          class="mb-2"
          label
          @click.stop="onPageClick(relatedPage.id)"
        >
          {{ relatedPage.texts.title }}
        </VChip>
      </VCol>
    </VRow>
  </ARow>
</template>
