<script lang="ts" setup>
import { type IntegerId } from '@anzusystems/common-admin'
import { useArticleStageListActions } from '@/views/cms/articleStage/composables/articleStageActions'
import { computed } from 'vue'
import { useArticleStageListStore } from '@/stores/cms/articleStageStore'
import ATableActions from '@/components/ATableActions.vue'
import { ROUTE } from '@/router/routes'
import { ACL } from '@/composables/auth/auth'
import { useMainBarDialogActions } from '@/views/cms/article/components/mainBarButtons/mainBarDialogActions'

const props = withDefaults(
  defineProps<{
    id: null | IntegerId | undefined
  }>(),
  {}
)

const articleStageListStore = useArticleStageListStore()
const { articleRelLoading } = useArticleStageListActions()

const article = computed(() => {
  return articleStageListStore.getArticleByArticleStage(props.id ?? 0)
})

const { onRowClick } = useMainBarDialogActions()
</script>

<template>
  <VProgressCircular
    v-if="articleRelLoading"
    :size="12"
    :width="2"
    indeterminate
    class="mx-1"
  />
  <ATableActions
    v-else-if="article"
    :id="article.id"
    custom-edit-callback
    :override-copy-id="article.docId"
    :detail-route-name="ROUTE.CMS.ARTICLE.DETAIL"
    :detail-route-acl="ACL.CMS_ARTICLE_READ"
    :edit-route-name="ROUTE.CMS.ARTICLE.EDIT"
    :edit-route-acl="[ACL.CMS_ARTICLE_READ, ACL.CMS_ARTICLE_UPDATE]"
    @on-edit-click="onRowClick(article)"
  />
</template>
