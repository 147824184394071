import { SYSTEM_CMS } from '@/model/systems'
import { makeFilterHelper } from '@anzusystems/common-admin'
import { reactive } from 'vue'

const makeFilter = makeFilterHelper(SYSTEM_CMS, 'articleKind')

const filter = reactive({
  _elastic: {
    ...makeFilter({ exclude: true }),
  },
  docId: {
    ...makeFilter({ name: 'docId', advanced: true }),
  },
  text: {
    ...makeFilter({ name: 'text' }),
  },
  headline: {
    ...makeFilter({ name: 'headline' }),
  },
  url: {
    ...makeFilter({ name: 'url', advanced: true }),
  },
  site: {
    ...makeFilter({ name: 'site', field: 'siteIds', default: [] }),
  },
  rubric: {
    ...makeFilter({ name: 'rubrics', field: 'rubricIds', default: [] }),
  },
  desk: {
    ...makeFilter({ name: 'desks', field: 'deskIds', default: [], advanced: true }),
  },
  status: {
    ...makeFilter({ name: 'status' }),
  },
  linkedList: {
    ...makeFilter({ name: 'linkedList', field: 'linkedListIds' }),
  },
  discriminator: {
    ...makeFilter({ name: 'discriminator' }),
  },
  lockType: {
    ...makeFilter({ name: 'lockType', advanced: true }),
  },
  publicPublishedAtFrom: {
    ...makeFilter({ name: 'publicPublishedAtFrom', advanced: true }),
  },
  publicPublishedAtUntil: {
    ...makeFilter({ name: 'publicPublishedAtUntil', advanced: true }),
  },
  modifiedAtFrom: {
    ...makeFilter({ name: 'modifiedAtFrom', advanced: true }),
  },
  modifiedAtUntil: {
    ...makeFilter({ name: 'modifiedAtUntil', advanced: true }),
  },
  owners: {
    ...makeFilter({ name: 'owners', field: 'ownerIds', default: [], advanced: true }),
  },
  keywords: {
    ...makeFilter({ name: 'keywords', field: 'keywordIds', default: [] }),
  },
  articleAuthors: {
    ...makeFilter({ name: 'articleAuthors', field: 'authorIds', default: [] }),
  },
})

export function useArticleListFilter() {
  return filter
}

export function useArticleFilter() {
  return reactive({
    _elastic: {
      ...makeFilter({ exclude: true }),
    },
    docId: {
      ...makeFilter({ name: 'docId' }),
    },
    keywords: {
      ...makeFilter({ name: 'keywords', field: 'keywordIds', default: [] }),
    },
    linkedList: {
      ...makeFilter({ name: 'linkedList', field: 'linkedListIds', default: [] }),
    },
  })
}

const deskListFilter = reactive({
  _elastic: {
    ...makeFilter({ exclude: true }),
  },
  status: {
    ...makeFilter({ name: 'status' }),
  },
  desk: {
    ...makeFilter({ name: 'desks', field: 'deskIds', default: [] }),
  },
  discriminator: {
    ...makeFilter({ name: 'discriminator' }),
  },
})

export function useDeskArticleListFilter() {
  return deskListFilter
}

const personalListFilter = reactive({
  _elastic: {
    ...makeFilter({ exclude: true }),
  },
  status: {
    ...makeFilter({ name: 'status' }),
  },
  publicPublishedAtFrom: {
    ...makeFilter({ name: 'publicPublishedAtFrom', advanced: true }),
  },
  publicPublishedAtUntil: {
    ...makeFilter({ name: 'publicPublishedAtUntil', advanced: true }),
  },
  createdOrAuthoredByUser: {
    ...makeFilter({ name: 'createdOrAuthoredByUser', field: 'createdOrAuthoredByUserIds', default: [] }),
  },
  discriminator: {
    ...makeFilter({ name: 'discriminator' }),
  },
})

export function usePersonalArticleListFilter() {
  return personalListFilter
}
