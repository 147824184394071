<script setup lang="ts">
import { Chart } from 'highcharts-vue'
import { computed } from 'vue'
import Highcharts from 'highcharts'

const props = withDefaults(
  defineProps<{
    options: Highcharts.Options
  }>(),
  {}
)

const defaultOptions: Highcharts.Options = {
  time: {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
  chart: {
    type: 'pie',
    spacing: [0, 0, 0, 0],
    height: '25%',
  },
  tooltip: {
    pointFormat: '<b>{point.y}</b> ({point.percentage:.1f}%)',
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  accessibility: {
    enabled: false,
  },
  title: {
    text: '',
    align: 'left',
    style: {
      color: '#FF00FF',
      fontWeight: 'bold',
    },
  },
}

const mergedOptions = computed(() => {
  return { ...defaultOptions, ...props.options }
})
</script>

<template>
  <Chart :options="mergedOptions" />
</template>
