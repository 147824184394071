import type { DashboardDtoInterface } from '@/types/cms/Dashboard'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

export const useDashboardListStore = defineStore('cmsDashboardListStore', () => {
  const listItems = ref<DashboardDtoInterface[]>([])
  const listItemsGrouped = ref<Map<string, DashboardDtoInterface[]>>(new Map())

  function reset() {
    listItems.value = []
    listItemsGrouped.value.clear()
  }

  function setListGrouped(list: DashboardDtoInterface[]) {
    list.forEach((dashboard: DashboardDtoInterface) => {
      const data = listItemsGrouped.value.get(dashboard.type) || []
      data.push(dashboard)

      listItemsGrouped.value.set(dashboard.type, data)
    })
  }

  return {
    listItems,
    listItemsGrouped,
    reset,
    setListGrouped,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDashboardListStore, import.meta.hot))
}
