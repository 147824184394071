<script lang="ts" setup>
import { useDashboardListActions } from '@/views/cms/dashboard/composables/dashboardActions'
import { useDashboardListStore } from '@/stores/cms/dashboardStore'
import { onMounted, onUnmounted } from 'vue'
import { ACard, ARow } from '@anzusystems/common-admin'
import BaseDashboard from '@/views/cms/dashboard/components/BaseDashboard.vue'
import { type DashboardTypeType, useDashboardTypes } from '@/model/cms/valueObject/DashboardType'

const props = withDefaults(
  defineProps<{
    type?: DashboardTypeType | undefined
  }>(),
  {
    type: undefined,
  }
)

const { fetchList, listLoading, listItemsGrouped } = useDashboardListActions()
const dashboardListStore = useDashboardListStore()

const { getDashboardTypeOption } = useDashboardTypes()

onMounted(() => {
  fetchList(props.type)
})

onUnmounted(() => {
  dashboardListStore.reset()
})
</script>

<template>
  <ACard :loading="listLoading">
    <VCard
      v-for="[dashboardName, dashboards] in listItemsGrouped"
      :key="dashboardName"
    >
      <VCardTitle>
        {{ getDashboardTypeOption(dashboardName as DashboardTypeType)?.title }}
      </VCardTitle>
      <VCardText>
        <ARow>
          <BaseDashboard
            v-for="dashboard in dashboards"
            :key="dashboard.id"
            :dashboard="dashboard"
          />
        </ARow>
      </VCardText>
    </VCard>
  </ACard>
</template>
