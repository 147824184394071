import type { Newsletter } from '@/types/cms/Newsletter'
import { NewsletterTypeDefault } from '@/model/cms/valueObject/NewsletterType'
import { dateTimeNow } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'

export function useNewsletterFactory() {
  const createDefaultNewsletter = (): Newsletter => {
    return {
      id: 0,
      texts: {
        title: '',
        titlePublic: '',
      },
      mailerId: '',
      type: NewsletterTypeDefault,
      site: 0,
      siteGroup: 0,
      rubric: 0,
      schedules: [],
      newsletterPromos: [],
      enabled: true,
      settings: {
        generators: [],
        emailTemplateMailLayoutId: null,
        emailTemplateMailTypeCode: null,
        emailTemplateFrom: null,
        emailTemplateSubject: null,
        emailTemplateSubjectDynamicParam: null,
        segmentProvider: null,
        segmentCode: null,
        segmentCodeWithWebAccess: null,
        segmentCodeWithoutWebAccess: null,
      },
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      _resourceName: 'newsletter',
      _system: SYSTEM_CMS,
    }
  }

  return {
    createDefaultNewsletter,
  }
}
